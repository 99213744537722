import React, { Component } from "react";
import { DateTimeFormat, Nl2br, UserStatus } from "../../resources/Filters";
import Text, { Xlate } from "../utils/Text";
import { Column, Row } from "../utils/Row";
import Button from "../utils/Button";

export default class SupportListItem extends Component {
	onChangeAssigned = (e) => {
		const adminId = e.target.value;
		const { data } = this.props;

		this.props.onAssign(data.id, adminId);
	};

	render() {
		const { data } = this.props;
		if (!data) return "";

		const { id, details, subject, created, email, status, read, solved, assigned_to, full_name, solved_date, typed_email, additional_details } = this.props.data;
		const { onReadToggle, onSolvedToggle, admins } = this.props;
		console.log(JSON.parse(decodeURI(details)));

		return (
			<div className="card">
				<div className="card-header font-weight-bold">
					<Row>
						<Column className="col-sm-auto">
							{full_name} &#32; &#124; &#32;{email ? email.trim() : ""} &#32; &#124; &#32; {JSON.parse(decodeURI(additional_details)).phoneNumber}
							{typed_email && (!email || typed_email.toLowerCase().trim() !== email.toLowerCase().trim()) ? "(" + typed_email + ")" : ""}
						</Column>

						<Column className="col-sm-auto">{DateTimeFormat(JSON.parse(decodeURI(additional_details)).creationDate)}</Column>

						<Column className="col-sm-auto">ticket ID: {id}</Column>

						<Column className="col-sm-auto">
							<Button primary={1} small={1} spaced={1} onClick={() => onSolvedToggle(data.id)}>
								<i className={"fas " + (solved ? "fa-times" : "fa-check")}></i>
							</Button>
							{!solved && (
								<Button info={1} small={1} onClick={() => onReadToggle(data.id)}>
									<i className={"fas " + (read ? "fa-eye-slash" : "fa-eye")}></i>
								</Button>
							)}
						</Column>
					</Row>
				</div>
				<div className="card-body">
					{subject && subject !== "Bug Report" && <h4 style={{ textAlign: "left", marginBottom: "50px" }}>{decodeURI(subject)}</h4>}
					{JSON.parse(decodeURI(details)).map((msg) => {
						return (
							msg.content && (
								<p style={{ textAlign: "left", direction: "ltr", fontWeight: msg.isUser ? "regular" : "bold" }}>
									{msg.isUser ? "A:  " : "Q:  "}
									{msg.content}
								</p>
							)
						);
					})}
					<p style={{ textAlign: "left", direction: "ltr", fontSize: "12px" }}>
						{JSON.parse(decodeURI(additional_details)).platform} <span style={{ fontSize: "15px", fontWeight: "bold", margin: "20px" }}>|</span> {JSON.parse(decodeURI(additional_details)).version} <span style={{ fontSize: "15px", fontWeight: "bold", margin: "20px" }}>|</span>{" "}
						{JSON.parse(decodeURI(additional_details)).deviceVersion}
						<span style={{ fontSize: "15px", fontWeight: "bold", margin: "20px" }}>|</span> user type: {JSON.parse(decodeURI(additional_details)).userType}
					</p>
				</div>
				<div className="card-footer">
					<Text>support.assignedTo</Text>
					<select value={assigned_to > 0 ? assigned_to + "" : ""} onChange={this.onChangeAssigned}>
						<option>{Xlate("support.chooseAdmin")}</option>
						{admins &&
							admins.map((admin, index) => (
								<option key={index} value={admin.id}>
									{admin.full_name}
								</option>
							))}
					</select>
				</div>
			</div>
		);
	}
}
