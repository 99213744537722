import React, {PureComponent} from 'react';

export class Row extends PureComponent {

	render() {
		const children = React.Children.toArray(this.props.children);
		return (<div className={'row' + (this.props.centered?' align-items-center':'') + (this.props.className?' '+this.props.className:'')}>
			{children.map((col,index) =>
				(col.type && (col.type === Column || col.type === <Column /> ))? col :
					<div className="col" key={index}>{col}</div>
			)}
		</div>)
	}
}

export class Column extends PureComponent {

	render() {

		const {className, autoCol, children} = this.props;

		const colClass =
				((!className || className.indexOf('col-')<0)?'col':'') +
				(autoCol?' col-auto':'') +
				(className?' '+className:'');

		return (
			<div className={colClass}>
				{children}
				</div>
		)
	}
}
