import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import Text from "../components/utils/Text";

class Logout extends Component {

	componentDidMount() {
		this.props.loginActions.logout();

		setTimeout(() => this.props.history.push('/'), 1000);
	}

	render() {

		return (
			<div>
				<h1><Text>login.loggingOut</Text></h1>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {login: state.login}
};

const mapDispatchToProps = dispatch => {
	return { loginActions: bindActionCreators({...loginActions}, dispatch) }
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);