import {
	GET_COMPANIES,
	GET_COMPANY_REPORT,
	GET_COMPANY_REPORT_ERROR
} from '../actions/labellingActions';

const defaultState = {
	companies : [],
	report: null
};

export default function LabellingReducer (state = defaultState, action) {
	switch (action.type) {

		case GET_COMPANIES:
			return { ...state,
				companies: action.list, 
		};

		case GET_COMPANY_REPORT: 
			return { ...state, report: action.report }

		default:
			return state;
	}

};
