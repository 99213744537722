export const ilTags = {
    "2": "אסייתי",
    "6": "המבורגר",
    "7": "ארוחת בוקר",
    "8": "פיצה",
    "9": "מתוקים",
    "10": "מקסיקני",
    "11": "תאילנדי",
    "12": "הודי",
    "14": "חומוס פלאפל",
    "17": "בריא",
    "20": "שייקים",
    "24": "פסט פוד",
    "29": "כשר",
    "31": "מאפים",
    "32": "אופציות נטולות גלוטן",
    "40": "אתיופי ",
    "43": "סלטים",
    "44": "גלידה",
    "45": "אלכוהול",
    "50": "אסייתי",
    "52": "פסטה",
    "53": "משלוחים",
    "61": "בצק",
    "62": "שוקולד",
    "63": "נעליים",
    "64": "ללא עור",
    "65": "טיפוח",
    "66": "ריפוי",
    "67": "טיפול",
    "68": "קליניקה",
    "69": "נטורופתיה",
    "70": "אופנה",
    "71": "תיקים",
    "72": "חתונות",
    "73": "אקססוריז",
    "74": "יין",
    "75": "נופש",
    "76": "צימר",
    "77": "ספא",
    "78": "חיות מחמד",
    "79": "כלבים",
    "80": "מתנות",
    "81": "אקולוגי",
    "84": "גבינות טבעוניות",
    "85": "ספורט",
    "86": "ממרחים",
    "87": "תוספי תזונה",
    "88": "חטיפים",
    "89": "שניצל",
    "90": "תחליפי בשר",
    "91": "בישול",
    "92": "אימוני כושר",
    "93": "סדנאות",
    "94": "תזונה בריאה",
    "96": "גלידה",
    "97": "מזונות-על (superfoods)",
    "98": "מסעדות שף/גורמה",
    "99": "רשתות",
    "100": "אירועים",
    "101": "חיי לילה",
    "102": "מתכונים טבעוניים",
    "103": "תחליפי חלב",
    "104": "תחליפי ביצים",
    "105": "מנות טבעוניות ",
    "106": "תפריט טבעוני ",
    "107": "אוכל טבעוני",
    "108": "טבעונות",
    "109": "בית קפה",
    "110": "שעונים",
    "111": "משקאות",
    "112": "תה",
    "113": "כלים מתכלים",
    "114": "נעליים",
    "115": "ארנקים",
    "116": "צעיפים",
    "117": "פנקייקים",
    "118": "לחמניות",
    "119": "ג'חנון",
    "120": "מלוואח",
    "121": "חגורה",
    "122": "חגורות",
    "123": "יוגה",
    "124": "ציוד ספורט",
    "125": "אוכל ביתי"
}

export const ilFoodCategories = {
    "1": "אופציות טבעוניות",
    "2": "100% טבעוני ",
    "3": "פיצריות",
    "5": "מתוקים",
    "6": "אסייתי",
    "7": "מזרח תיכוני",
    "8": "פאבים",
    "9": "איטלקי",
    "10": "חו\"ל",
    "11": "בית קפה",
    "12": "מאפיה",
    "13": "בריאות",
    "14": "אתיופי",
    "15": "מקסיקני",
    "16": "הודי",
    "17": "אוכל מהיר"
}

export const categoryMapper = [
    {
        name: 'פסטה',
        tags: [52],
        categories: []
    },
    {
        name: 'חומוס',
        tags: [14],
        categories: []
    },
    {
        name: 'אסיאתי',
        tags: [2, 50],
        categories: [6]
    },
    {
        name: 'מאפייה',
        tags: [31, 61],
        categories: [12]
    },
    {
        name: 'ארוחת בוקר',
        tags: [7],
        categories: []
    },
    {
        name: 'המבורגר',
        tags: [6],
        categories: []
    },
    {
        name: 'בית קפה',
        tags: [109],
        categories: [11]
    },
    {
        name: 'סיני',
        tags: [],
        categories: []
    },
    {
        name: 'חנות נוחות',
        tags: [],
        categories: []
    },
    {
        name: 'קינוח',
        tags: [9],
        categories: [5]
    },
    {
        name: 'אתיופי',
        tags: [40],
        categories: [14]
    },
    {
        name: 'אוכל עדות',
        tags: [],
        categories: []
    },
    {
        name: 'אוכל ביתי',
        tags: [125],
        categories: []
    },
    {
        name: 'גלידה',
        tags: [44, 96],
        categories: []
    },
    {
        name: 'הודי',
        tags: [12],
        categories: [16]
    },
    {
        name: 'איטלקי',
        tags: [],
        categories: [9]
    },
    {
        name: 'יפני',
        tags: [],
        categories: []
    },
    {
        name: "מיצים / שייקים",
        tags: [20],
        categories: []
    },
    {
        name: 'ים תיכוני',
        tags: [],
        categories: [7]
    },
    {
        name: 'מקסיקני',
        tags: [10],
        categories: [15]
    },
    {
        name: 'פיצה',
        tags: [8],
        categories: [3]
    },
    {
        name: 'סלט',
        tags: [43],
        categories: []
    },
    {
        name: 'כריכים',
        tags: [],
        categories: []
    },
    {
        name: 'סושי',
        tags: [],
        categories: []
    },
    {
        name: 'תאילנדי',
        tags: [11],
        categories: []
    },
    {
        name: 'אלכוהול',
        tags: [45],
        categories: [8]
    }


]
