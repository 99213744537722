import React, {PureComponent} from 'react';
import Icon from "./Icon";
import Button from "./Button";
import Text from "./Text";

export default class Confirmation extends PureComponent {

	onConfirm = () => {
		const {onDismiss, onConfirm} = this.props;
		onConfirm();
		if (onDismiss) onDismiss();
	};

	onDismiss = () => {
		const {onDismiss} = this.props;
		if (onDismiss) onDismiss();
	};

	render() {
			
		return (
			<div className="alert alert-danger alert-confirmation">
				<Text>actions.areYouSure</Text>
				<div>
				<Button danger={1} spaced={1} onClick={this.onConfirm}>
					<Icon>check</Icon> <Text>yes</Text>
				</Button>
				<Button light={1} spaced={1} onClick={this.onDismiss}>
					<Icon>times</Icon> <Text>no</Text>
				</Button>
				</div>
			</div>
		)
	}
};

