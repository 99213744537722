import React, {PureComponent} from 'react';
import Text from "../utils/Text";
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";

export default class LineInput extends PureComponent {

	constructor() {
		super();
		this.state = {
			text : '',
		}
	}

	componentDidMount() {
		const { value } = this.props;
		if (value) {
			this.setState({text : value});
		}
	}

	onChange = (e) => {

		const {noButtons} = this.props;

		this.setState({text : e.target.value });

		if (noButtons) setTimeout(() => this.onSubmit(), 1);
	};

	onSubmit = () => {
		this.props.onSubmit(this.state.text)
	};

	onDismiss = () => {
		if (this.props.onDismiss) this.props.onDismiss()
	};

	render() {

		const {text} = this.state;
		const {noButtons, icon, buttonLabel} = this.props;

		return (
				<form onSubmit={this.onSubmit}>
					<Row>
						{icon ?
							<div className="search-wrapper">
								<input type="text" className="form-control" onChange={this.onChange} value={text} />
								<Icon>{icon}</Icon>
							</div>
							:
							<input type="text" className="form-control" onChange={this.onChange} value={text} />
						}
						{!noButtons && <Column autoCol={1}>
							<Button submit={1} primary={1} onClick={this.onSubmit}>{buttonLabel?buttonLabel:<Text>menus.add</Text>}</Button>
							{this.props.onDismiss && <Button spaced={1} secondary={1} onClick={this.onDismiss}><Icon>times</Icon></Button>}
						</Column>}
					</Row>
				</form>
		)
	}
}