import React, {PureComponent} from 'react';
import {Xlate} from "./Text";
import {Column, Row} from "./Row";
import Button from "./Button";
import Icon from "./Icon";

export default class Select extends PureComponent {

	constructor() {
		super();
		this.state = {
			selected : ''
		}
	}

	componentDidMount() {
		const {selected} = this.props;

		if (selected) {
			this.setState({selected});
		}
	}

	onChange = (e) => {

		const {noButtons, refId, onSelect} = this.props;
		const {value} = e.target;

		this.setState({selected : value});

		if (noButtons) onSelect(value, refId);
	};

	render() {

		let {options, refId, cancel, noButtons, buttonLabel, onSelect, emptyOption, exclude, sort} = this.props;
		const {selected} = this.state;

		if (exclude) {
			options = options.filter(section => {
				for (let s of exclude) if (s.value*1 === section.value*1) return false;
				return true;
			})
		}

		if (sort) {
			options = options.sort(sort);
		}

		return (
			<Row>
				<select className="form-control" onChange={this.onChange} value={selected}>
					{emptyOption && <option>- {emptyOption} -</option>}
					{options.map(option => <option key={option.value} onChange={this.onChange} value={option.value}>{option.label}</option>)}
				</select>
				{!noButtons && <Column autoCol={1}>
					<Button primary={1} onClick={() => onSelect(selected, refId)}>{buttonLabel || Xlate('actions.select')}</Button>
					{cancel && <Button light={1} onClick={cancel}><Icon>times</Icon></Button>}
				</Column>}
			</Row>
		)
	}
};