import {
	LOADING,
	ADMINS_GET_LIST_SUCCESS,
	ADMINS_ERROR,
	ADMINS_CREATE_SUCCESS,
	ADMINS_EDIT_SUCCESS,
	ADMINS_DELETE_SUCCESS,
	GENERATE_HASH_RESET,
	GENERATE_HASH_SUCCESS
} from '../actions/adminsActions';

const defaultState = {
	adminsList : [],
	error : null,
	loading: false,
	generatedHash : null,
};

export default function UsersReducer (state = defaultState, action) {

	let index;

	switch (action.type) {

		case LOADING : {
			return {...state, loading: true }
		}

		case ADMINS_ERROR : {
			return {...state, loading: false, error: action.error }
		}

		case ADMINS_GET_LIST_SUCCESS: {

			let {list} = action;
			list = list.map(item => ({...item, superAdmin : !!(item.is_super * 1), active : !!(item.active * 1)}));

			return {...state, loading: false, adminsList: list }
		}

		case ADMINS_CREATE_SUCCESS: {
			return {...state, adminsList : [...state.adminsList, {id: action.id, username: action.username, full_name: action.full_name }]}
		}

		case ADMINS_EDIT_SUCCESS: {
			index = state.adminsList.findIndex(admin => admin.id*1 === action.adminId*1);
			return { ...state, tickets : [...state.adminsList.slice(0,index), {...state.adminsList[index], username: action.username}, ...state.adminsList.slice(index+1)]};
		}

		case ADMINS_DELETE_SUCCESS: {
			index = state.adminsList.findIndex(admin => admin.id*1 === action.adminId*1);
			return { ...state, tickets : [...state.adminsList.slice(0,index), ...state.adminsList.slice(index+1)]};
		}

		case GENERATE_HASH_RESET: {
			return { ...state, generatedHash: null}
		}

		case GENERATE_HASH_SUCCESS: {
			return {...state, loading: false, generatedHash: action.hash }
		}

		default:
			return state;
	}

};
