import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as supportActions from '../actions/supportActions';
import Text from "../components/utils/Text";
import SupportListRow from "../components/support/SupportListRow";
import SupportListItem from "../components/support/SupportListItem";
import {Column, Row} from "../components/utils/Row";
import Icon from "../components/utils/Icon";


class Support extends Component {

	constructor() {
		super();
		this.state = {
			openTicket : null,
			data : null,
		}
	}

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		this.setOpenTicket(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.match) this.setOpenTicket(nextProps);
	}

	onToggleShowSolved = () => {
		const {supportActions} = this.props;
		supportActions.toggleShowSolvedTickets();
	};

	onSelectTicket = (ticketId) => {
		const {supportActions} = this.props;
		const {tickets} = this.props.support;
		let data = null;
		for (let ticket of tickets) { if (ticket.id*1 === ticketId*1) { data = ticket; break; }}

		if (data) {
			this.setState({openTicket : ticketId, data });

			if (!data.read) {
				supportActions.setSupportTicketRead(ticketId);
			}

			this.props.history.push('/support/'+ticketId);
		}
	};

	setOpenTicket(props) {
		const {tickets} = props.support;

		if (props.match.params && props.match.params['id']) {
			const id = props.match.params['id']*1;
			let data = null;
			for (let ticket of tickets) { if (ticket.id*1 === id) { data = ticket; break; }}

			if (data) {
				this.setState({openTicket : id, data });
			}
		}
	}

	onReadToggle = (ticketId) => {
		const {tickets} = this.props.support;
		const {supportActions} = this.props;

		const index = tickets.findIndex(ticket => ticket.id*1 === ticketId*1);
		if (index > -1) {
			const ticket = tickets[index];
			if (ticket.read) {
				supportActions.setSupportTicketUnread(ticket.id);
			}
			else {
				supportActions.setSupportTicketRead(ticket.id);
			}
		}
	};

	onSolvedToggle = (ticketId) => {
		const {tickets} = this.props.support;
		const {supportActions} = this.props;

		const index = tickets.findIndex(ticket => ticket.id*1 === ticketId*1);
		if (index > -1) {
			const ticket = tickets[index];
			if (ticket.solved) {
				supportActions.setSupportTicketUnsolved(ticket.id);
			}
			else {
				supportActions.setSupportTicketSolved(ticket.id);
			}
		}
	};

	onAssign = (ticketId, adminId) => {
		const {supportActions} = this.props;
		supportActions.assignSupportTicketToAdmin(ticketId, adminId);
	};

	render() {

		const {openTicket, data} = this.state;
		const {showSolved} = this.props.support;
		const {adminsList} = this.props.admins;
		const tickets = (showSolved)? this.props.support.tickets : this.props.support.tickets.filter(ticket => !ticket.solved);
		const openSupportTickets = (tickets) ? tickets.filter(ticket => !ticket.solved).length : 0;

		if (!tickets) return '';

		return (
			<div>
				<div className="card">
					<div className="card-body">
						<Row centered={1}>
							<Column className={'col-md'}><h5 className="card-title text-danger"><Text>sections.support</Text></h5></Column>
							<Column className={'col-auto-md'}>
								<input type="checkbox" className="mx-2" checked={showSolved} onChange={this.onToggleShowSolved} />
								<Text>support.showSolvedTickets</Text>
							</Column>
						</Row>
					</div>
					<ul className="list-group list-group-flush">
						<li className="list-group-item list-group-item-light">{openSupportTickets>0 && <Icon className="text-warning mx-2">exclamation-triangle</Icon>}
							<span className="font-weight-bold text-dark"> <Text num={openSupportTickets}>support.numOpenTickets</Text></span>
						</li>
					</ul>
					<Row className="px-3">
						<Column className={'px-0 '+(openTicket?'col-md-4':'col-md-12')}>
							<div className="support-list list-group">
								{tickets.length === 0 && // No tickets
								<div className={"card"}>
									<div className={'card-body'}>
										<h5 className={"card-title text-success"}><Text>support.noTickets</Text></h5>
										<p className={'card-text'}><Text>support.noTicketsText</Text></p>
									</div>
								</div>
								}
								{tickets.map(item => <SupportListRow admins={adminsList} onSelectTicket={this.onSelectTicket} openTicket={openTicket} key={item.id} data={item}/>)}
							</div>
						</Column>
						{openTicket && <Column className="px-0 col-md-8">
							<SupportListItem admins={adminsList} onAssign={this.onAssign} onReadToggle={this.onReadToggle} onSolvedToggle={this.onSolvedToggle} data={data}/>
						</Column>}
					</Row>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {login: state.login, support: state.support, admins: state.admins }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		supportActions: bindActionCreators({...supportActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Support);