import {Api} from "../resources/Api";
import { SET_CONTACT_TICKET_SOLVED_ERROR } from "./contactActions";

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';
export const GET_COMPANY_REPORT = 'GET_COMPANY_REPORT';
export const GET_COMPANY_REPORT_ERROR = 'GET_COMPANY_REPORT_ERROR';

export const getCompanies = () => {
	return async (dispatch) => {
		const res = await Api.getCompanies();
		
		if (res.success) {
			return dispatch({ type: GET_COMPANIES, list: res.data });
		}
		else {
			return dispatch({ type: GET_COMPANIES_ERROR, error: res.error });
		}
	}
};

export const createReport = (id) => {
	return async (dispatch) => {
		const res = await Api.createReport(id); 
		console.log('got this response: ' + JSON.stringify(res))

		if(res.success) {
			return dispatch({
				type: GET_COMPANY_REPORT, report: res.data.report 
			})
		} 
		else {

			return dispatch({
				type: GET_COMPANY_REPORT_ERROR
			})		
		}

	}
}
export const migrateProducts = (id) => {
	return async dispatch => {
		const res = await Api.migrateProducts(id); 
		if(res.success) {
			return { success: res.data.success }
		}
		else {
			return { success: false }
		}
		
	}
}
