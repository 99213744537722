import {Api} from "../resources/Api";

export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_ERROR = 'GET_RESTAURANTS_ERROR';
export const RELOAD_RESTAURANTS_SUCCESS = 'RELOAD_RESTAURANTS_SUCCESS';
export const RELOAD_RESTAURANTS_ERROR = 'RELOAD_RESTAURANTS_ERROR';
export const REGENERATE_RESTAURANTS_SUCCESS = 'REGENERATE_RESTAURANTS_SUCCESS';
export const REGENERATE_RESTAURANTS_ERROR = 'REGENERATE_RESTAURANTS_ERROR';
export const GET_MENU_SECTIONS_SUCCESS = 'GET_MENU_SECTIONS_SUCCESS';
export const GET_MENU_SECTIONS_ERROR = 'GET_MENU_SECTIONS_ERROR';
export const MENU_SECTIONS_MERGE_SUCCESS = 'MENU_SECTIONS_MERGE_SUCCESS';
export const MENU_SECTIONS_MERGE_ERROR = 'MENU_SECTIONS_MERGE_ERROR';
export const MENU_SECTIONS_SORT = 'MENU_SECTIONS_SORT';
export const MENU_SECTIONS_EDIT = 'MENU_SECTIONS_EDIT';
export const MENU_SECTIONS_ADD = 'MENU_SECTIONS_ADD';
export const ADD_RESTAURANT_MENU_SECTION = 'ADD_RESTAURANT_MENU_SECTION';
export const CLEAR_MENU_ITEMS = 'CLEAR_MENU_ITEMS';
export const GET_MENU_ITEMS = 'GET_MENU_ITEMS';
export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const EDIT_MENU_ITEM = 'EDIT_MENU_ITEM';
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const LOADING = 'LOADING_MENUS';

///////////////////////////////////////////////////////////////////////////////
// Restaurants
///////////////////////////////////////////////////////////////////////////////

export const getRestaurants = () => {
	return async (dispatch) => {
		const res = await Api.restaurantsList();

		if (res.result === "success") {
			return dispatch({
				type: GET_RESTAURANTS_SUCCESS,
				list: res.list
			})
		}
		else {
			return dispatch({
				type: GET_RESTAURANTS_ERROR,
				error: "empty"
			});
		}
	}
};

export const reloadRestaurants = () => {
	return async (dispatch) => {
		dispatch({type: LOADING});
		const res = await Api.reloadRestaurants();

		if (res.result === "success") {
			return dispatch({
				type: RELOAD_RESTAURANTS_SUCCESS,
			})
		}
		else {
			return dispatch({
				type: RELOAD_RESTAURANTS_ERROR,
			});
		}
	}
};

export const regenerateRestaurants = () => {
	return async (dispatch) => {
		dispatch({type: LOADING});
		const res = await Api.regenerateRestaurants();

		if (res.result === "success") {
			return dispatch({
				type: REGENERATE_RESTAURANTS_SUCCESS,
			})
		}
		else {
			return dispatch({
				type: REGENERATE_RESTAURANTS_ERROR,
			});
		}
	}
};

///////////////////////////////////////////////////////////////////////////////
// Menu Sections
///////////////////////////////////////////////////////////////////////////////

export const getMenuSections = () => {
	return async (dispatch) => {
		const res = await Api.menuSectionsList();

		if (res.result === "success") {
			res.list = res.list.sort((a,b) => b.title<a.title?1:-1);

			return dispatch({
				type: GET_MENU_SECTIONS_SUCCESS,
				list: res.list
			})
		}
		else {
			return dispatch({
				type: GET_MENU_SECTIONS_ERROR,
				error: "empty"
			});
		}
	}
};

export const sortMenuSections = (sections) => {
	return async (dispatch) => {

		await Api.menuSectionsSort(sections);
		return dispatch({
			type: MENU_SECTIONS_SORT,
			sections
		});
	}
};


export const addMenuSection = (title, position) => {
	return async (dispatch) => {
		const res = await Api.menuSectionsAdd(title, position);
		if (res.result === "success") {
			return dispatch({
				type: MENU_SECTIONS_ADD,
				title, position,
				id : res.id
			});
		}
	}
};

export const editMenuSection = (id, title) => {
	return async (dispatch) => {
		await Api.menuSectionsEdit(id, title);
		return dispatch({
			type: MENU_SECTIONS_EDIT,
			id, title
		});
	}
};


export const menuSectionsMerge = (oldSection, newSection) => {
	return async (dispatch) => {
		const res = await Api.menuSectionsMerge(oldSection, newSection);

		if (res.result === "success") {
			return dispatch({
				type: MENU_SECTIONS_MERGE_SUCCESS,
				oldSection, newSection
			})
		}
		else {
			return dispatch({
				type: MENU_SECTIONS_MERGE_ERROR
				,
				error: res.error
			});
		}
	}
};

export const addRestaurantMenuSection = (sectionId) => {
	return (dispatch) => dispatch({
		type: ADD_RESTAURANT_MENU_SECTION,
		sectionId
	});
};

///////////////////////////////////////////////////////////////////////////////
// Menu Items
///////////////////////////////////////////////////////////////////////////////

export const clearMenuItems = () => {
	return (dispatch) => dispatch({
		type: CLEAR_MENU_ITEMS
	})
}

export const menuItemsList = (restaurantId) => {

	return async (dispatch) => {

		dispatch({ type: LOADING });

		const menuItems = await Api.menuItemsList(restaurantId);

		const sections = [];
		if (menuItems instanceof Array) {
			for (let menuItem of menuItems) {
				if (sections.indexOf(menuItem.section_id) <= -1) {
					sections.push(menuItem.section_id);
				}
			}

			return dispatch({
				type: GET_MENU_ITEMS,
				menuItems,
				sections
			});
		}
	}
};

export const addMenuItem = (restaurantId, sectionId, title, description, price) => {
	return async (dispatch) => {

		dispatch({ type: LOADING });

		const res = await Api.menuItemAdd(restaurantId, sectionId, title, description, price);

		if (res.result === "success") {
			return dispatch({
				type: ADD_MENU_ITEM,
				id: res.id, restaurantId, sectionId, title, description, price
			});
		}
	}
};

export const editMenuItem = (itemId, title, description, price) => {
	return async (dispatch) => {

		dispatch({ type: LOADING });

		const res = await Api.menuItemEdit(itemId, title, description, price);

		if (res.result === "success") {
			return dispatch({
				type: EDIT_MENU_ITEM,
				itemId, title, description, price
			});
		}
	}
};


export const deleteMenuItem = (itemId) => {
	return async (dispatch) => {

		dispatch({ type: LOADING });

		await Api.menuItemDelete(itemId);
		return dispatch({
			type: DELETE_MENU_ITEM,
			itemId
		});
	}
};
