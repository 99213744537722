import React, { PureComponent } from 'react';
import { Column, Row } from "../utils/Row";
import Button from "../utils/Button";
import Text, { Xlate } from "../utils/Text";
import { DateTimeFormat, UserStatus } from "../../resources/Filters";

function Clip(props) {
	return (
		<video width="400" autoplay>
			<source src={props.url} type='video/mp4'/>
		</video>
	)
}

export default class ReviewRow extends PureComponent {

	constructor() {
		super();
		this.state = {
			deleting: false,
		}
	}

	getBranch(branchId, restaurant) {
		if (!restaurant || !restaurant.branches) return '';
		for (let b of restaurant.branches) { if (b[0] * 1 === branchId * 1) return b[1]; }
		return '';
	}

	render() {

		const { review, onApproveReview, onDeclineReview } = this.props;
		const { restaurant } = review;

		return (
			<ul key={review.id} className="list-group list-group-flush">
				<li className="list-group-item">
					<Row centered={1}>
						<Column className={'col-md-2 col-lg-1 text-center'}>
							<div className={'btn disabled btn-lg font-weight-bold btn-block ' +
								(review.score > 4 ? ' btn-success' : review.score > 3 ? ' btn-info' : review.score > 2 ? ' btn-warning' : ' btn-danger')}>{review.rating}</div>
						</Column>
						<Column className="col-md-3">
							<div className="font-weight-bold">{review.entity_name ? `${review.entity_name} (${review.entity_type})` : '-- ' + Xlate('notFound') + ' --'}</div>
							{(review && review.score > 0) && <div className={"text-info"}>{parseFloat(review.score).toFixed(2)}</div>}
						</Column>
						<Column className="col-md">
							<div className="pb-2">{decodeURI(review.description)}</div>
							<div className="card bg-light mx-0">
								<div className="card-body py-1">
									<Row centered={1} className="text-secondary">
										<Column className="col-lg small font-weight-bold">{DateTimeFormat(review.created_at)}</Column>
										<Column className="col-lg">{decodeURI(review.reviewer_name)}</Column>
									</Row>
								</div>
							</div>
						</Column>
						<Column autoCol={1}>
							<Button primary={1} spaced={1} onClick={() => onApproveReview(review.id, review.entity_id, review.entity_type)}><Text>actions.confirm</Text></Button>
							<Button danger={1} onClick={() => onDeclineReview(review.id, review.rest_id)}><Text>actions.decline</Text></Button>
						</Column>
					</Row>
					{review.pics &&
						<Row>
							{review.pics.map(pic => (
								<Button>{<img style={{ height: 150, width: 150 }} src={pic} />}</Button>
							))}
						</Row>
					}
				</li>
			</ul>
		);
	}
}