import React, { useEffect } from 'react';
import './Modal.css';
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react';
import t from '../../resources/i18n';
import Button from '../utils/Button';
import { Api } from '../../resources/Api';
import { Alert } from 'react-bootstrap';
import { getPicText, getAddressText, getDescriptionText, getPhoneNumberText, 
         getTagText, getScheduleText, getDiscountListText, getInstagramUrlText, 
         getFacebookUrlText, getWebsiteUrlText, getTiktokUrlText, getReservationUrlText, 
         getMenuExistsText, getCategoryText } from '../../resources/EmailUtils';    


const introText = t.t('restaurantFields.intro');

const RestaurantModal = ({ restaurantObject, onClose, isRTL, tags, restaurantCategories }) => {
  const [restaurant, setRestaurant] = useState(restaurantObject);
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const [fullText, setFullText] = useState('');
  const [email, setEmail] = useState('');
  const [replyEmail, setReplyEmail] = useState('');

  const sendEmail = async () => {
    setSendingInProgress(true)
    try {
      const response = await Api.sendEmailToBusiness("עדכון פרטים באפליקציית ויגן פרנדלי", fullText.replaceAll('\n', '<br/>').replaceAll('\t', '&emsp;'), email, replyEmail, isRTL);
      
      await Api.updateRestaurantDaysSinceLastContact(restaurant.id);
    } catch (e) {
      Alert('ERROR!');
    }
    setSendingInProgress(false)
  }

  useEffect(() => {
    setRestaurant(restaurantObject)
    let fullText =
      introText + '\r\n'
      + getPicText(restaurantObject.logo) + '\r\n\n'
      + getAddressText(restaurantObject.address) + '\r\n\n'
      + getDescriptionText(restaurantObject.description) + '\r\n\n'
      + getPhoneNumberText(restaurantObject.phone_number) + '\r\n\n'
      + getTagText(t.t("restaurantFields.tags"), restaurantObject.tag_ids, tags) + '\r\n\n'
      + getTagText(t.t("restaurantFields.categories"), restaurantObject.rest_categories_ids, restaurantCategories) + '\r\n\n'
      + getScheduleText(restaurantObject.schedule) + '\r\n\n'
      + getDiscountListText(restaurantObject.discount_list) + '\r\n\n'
      + getInstagramUrlText(restaurantObject.instagram_url) + '\r\n\n'
      + getFacebookUrlText(restaurantObject.website_url) + '\r\n\n'
      + getWebsiteUrlText(restaurantObject.website_url) + '\r\n\n'
      + getTiktokUrlText(restaurantObject.tiktok_url) + '\r\n\n'
      + getReservationUrlText(restaurantObject.reservation_url) + '\r\n\n'
      + getMenuExistsText(t.t("restaurantFields.menu"), restaurantObject.menu) + '\r\n\n';


    if (restaurantObject.menu && restaurantObject.menu.categories) {
      for (let c of restaurantObject.menu.categories) {
        fullText += getCategoryText(c);
      };
      

      for(let pic of restaurantObject.menu.food_pics) {
        fullText += getPicText(pic.url)

      }

    }

    setFullText(fullText);
    setEmail(restaurantObject.email);
  }, [restaurantObject, restaurantObject.menu])

  return <Modal show={true} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>Restaurant: {restaurant.name}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div>Send Email to: </div>
      <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
      <div>Reply to: </div>
      <input type="text" value={replyEmail} onChange={e => setReplyEmail(e.target.value)} />
      <Button type="submit" success={1} disabled={sendingInProgress} onClick={sendEmail}>Submit</Button>
      <textarea type="text" cols={100} dir="rtl" rows={50} value={fullText} onChange={e => setFullText(e.target.value)} />
    </Modal.Body>
  </Modal>
}

export default RestaurantModal;

