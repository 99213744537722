import React, {PureComponent} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Text, {Xlate} from "../utils/Text";
import {DateTimeFormat, UserStatus} from "../../resources/Filters";
import Icon from "../utils/Icon";

export default class AdminAddRow extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			editing : false,
			deleting : false,
			username : '',
			password : '',
			full_name: ''
		}
	}

	onChange = (option, value) => {
		this.setState({[option] : value});
	};

	onAddAdmin = () => {
		this.props.onAddAdmin(this.state.username, this.state.password, this.state.full_name);
		if (this.props.onDismiss) this.props.onDismiss();
	};


	render() {

		const {admin, onApproveReview, onDeclineReview} = this.props;
		const {username, password, full_name} = this.state;

		return (
			<div>
				<form onSubmit={this.onAddAdmin}>
					<Row centered={1}>
						<input type="text" placeholder={Xlate('fields.username')} className="form-control" value={username} onChange={(e) => this.onChange('username', e.target.value)} />
						<input type="password" placeholder={Xlate('fields.password')} className="form-control" value={password} onChange={(e) => this.onChange('password', e.target.value)} />
						<br/>
						<input type="text" placeholder={Xlate('fields.full_name')} className="form-control" value={full_name} onChange={(e) => this.onChange('full_name', e.target.value)} />
						<Column className="col-auto font-weight-bold">
							<Button  type="submit" success={1} spaced={1} ><Icon>check</Icon></Button>
							<Button light={1} onClick={(this.props.onDismiss ? this.props.onDismiss : null)}><Icon>times</Icon></Button>
						</Column>
					</Row>
				</form>
			</div>
		);
	}
}