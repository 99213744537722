import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import { Api } from "../resources/Api";
import Multiselect from 'multiselect-react-dropdown';
import Button from '../components/utils/Button';
import Dropdown from 'react-dropdown';
import Select from 'react-select'
import 'react-dropdown/style.css';
import { options } from 'i18next';


class MigrationScriptReverse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedRestaurantId: -1,
			selectedChainId: -1,
			vfRestaurantId: '-1,-1',
			selectedValue: null,
			currentRestaurantName: '',
			inProgress: false,
			error: null,
			ukChains: null,
			ukRestaurants: null,
			ilRestaurants: [],
			restaurantEdit: false,
			chainEdit: false
		}
	}
	getChainList = (restaurants) => {
		const groupById = this.groupBy("restaurant_id");
		const groupedById = groupById(restaurants)

		const chainIds = [...Object.keys(groupedById)]//.filter(chain => chain.length > 3)];
		const chainList = [];

		for (let i of chainIds) {
			chainList.push({ ...groupedById[i][0], display_name: `${groupedById[i][0].restaurant_name} (${groupedById[i].length})` })
		}

		return chainList;
	}

	groupBy = (key) => {
		return function group(array) {
			return array.reduce((acc, obj) => {
				const property = obj[key];
				acc[property] = acc[property] || [];
				acc[property].push(obj);
				return acc;
			}, {});
		};
	}


	componentDidMount = async () => {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		let ukChains = await Api.getChains();
		let ukRestaurants = await Api.getRestaurants();
		ukRestaurants = ukRestaurants ? ukRestaurants.map(rest => {
			return {
				value: rest.id,
				label: rest.restaurant_name,
				...rest
			}
		}).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).filter(r => !r.chainId) : []

		ukChains = ukChains ? ukChains.map(chain => {
			return {
				value: chain.id,
				label: chain.name,
				...chain
			}
		}).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) : [];

		let ilRestaurantsAll = await Api.getIlRestaurantsFull();
		let ilRestaurants = this.getChainList(ilRestaurantsAll);//.filter(chain => chain.active === 1);
		//let ilRestaurants = [...ilRestaurantsAll.filter(rest => !ilChains.map(chain => chain.restaurant_id).includes(rest.restaurant_id))];

		console.log('these are the restaurants we got: ' + ilRestaurants.length);	

		ilRestaurants = ilRestaurants ? ilRestaurants.map(rest => {
			return {
				value: rest.restaurant_id + "," + rest.branch_id,
				label: rest.restaurant_name,
				...rest
			}
		}) : [];

		this.setState({ ukRestaurants, ukChains, ilRestaurants })
	}


	async onSubmitRestaurant() {
		const { selectedRestaurantId, vfRestaurantId, restaurantEdit } = this.state;

		let result = await Api.addRestaurantToVeganFriendly(selectedRestaurantId, restaurantEdit ? vfRestaurantId.split(',')[0] : null);
		alert(`got this response: ${JSON.stringify(result)}`)
	}

	async onSubmitChain() {
		const { selectedChainId, vfRestaurantId, chainEdit } = this.state;

		let result = await Api.addChainToVeganFriendly(selectedChainId, chainEdit ? vfRestaurantId.split(',')[0] : null);
		alert(`got this response: ${JSON.stringify(result)}`)
	}

	render() {

		const { login } = this.props;
		const { inProgress, error,
			ukRestaurants, ukChains,
			ilRestaurants,
			restaurantEdit, chainEdit,
			currentRestaurantName, currentChainName,
			selectedRestaurantId, selectedChainId,
		} = this.state;

		console.log('restaurant id: ' + selectedRestaurantId)
		return (
			<div>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							{!ukRestaurants && <h5 className="card-title text-secondary"><Text>migration.loading</Text></h5>}
							{ukRestaurants &&
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h5 className="card-title text-secondary"><Text>migration.numberOfRestaurants</Text><Text>{ukRestaurants.length}</Text></h5>

									<Select options={ukRestaurants} onChange={(item) => this.setState({ selectedRestaurantId: item.value })} placeholder="Select an option" />
									<label style={{ display: 'flex', flexDirection: 'column' }}>
										האם המסעדה קיימת?<input
											name="isGoing"
											type="checkbox"
											checked={restaurantEdit}
											onChange={() => this.setState({ restaurantEdit: !restaurantEdit })} />
									</label>
									{
										restaurantEdit && selectedRestaurantId !== -1 &&
										<Select options={ilRestaurants} onChange={(item) => this.setState({ vfRestaurantId: item.value })} placeholder="בחרי את המסעדה המתאימה בישראל" />

									}
									{<div><Text>{currentRestaurantName.length > 0 ? `Adding ${currentRestaurantName}` : `...`}</Text></div>}
									{error && <div><Text>{error}</Text></div>}

									<Button disabled={inProgress} success={1} onClick={() => {
											this.onSubmitRestaurant()
									}}><Icon>yes</Icon><Text>{restaurantEdit ? `migration.updateRestaurant` : `migration.startUpload`}</Text></Button>
								</div>}
							{ukChains &&
								<div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
									<h5 className="card-title text-secondary"><Text>migration.numberOfChains</Text><Text>{': ' + ukChains.length}</Text></h5>
									<Select options={ukChains} onChange={(item) => { this.setState({ selectedChainId: item.value })}} placeholder="Select an option" />

									<label style={{ display: 'flex', flexDirection: 'column' }}>האם הרשת קיימת?
										<input
											name="isGoing"
											type="checkbox"
											checked={chainEdit}
											onChange={() => this.setState({ chainEdit: !chainEdit })} />
									</label>
									{
										chainEdit && selectedChainId !== -1 &&
										<Select options={ilRestaurants} onChange={(item) => this.setState({ vfRestaurantId: item.value })} placeholder="בחרי את המסעדה המתאימה בישראל" />

									}
									{currentChainName && <div><Text>{currentChainName.length > 0 ? `Adding ${currentChainName}` : `...`}</Text></div>}
									{error && <div><Text>{error}</Text></div>}

									<Button disabled={inProgress} success={1} onClick={() => {
										this.onSubmitChain()
										}}><Icon>yes</Icon><Text>{chainEdit ? `migration.updateChain` : `migration.startUpload`}</Text></Button>

								</div>

							}
						</div>


					</div>}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(MigrationScriptReverse);