import {
	GET_VF_SERVICE_PROVIDERS,
	GET_VF_BUSINESSES
} from '../actions/migrationActions';

const defaultState = {
	vfBusinesses: [],
	vfServiceProviders: [],
};

export default function MigrationReducer(state = defaultState, action) {
	switch (action.type) {

		case GET_VF_SERVICE_PROVIDERS:
			return {
				...state,
				vfServiceProviders: action.list,
			};
		
		case GET_VF_BUSINESSES: 
			return {
				...state,
				vfBusinesses: action.list
			}

		default:
			return state;
	}

};
