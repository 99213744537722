import { TEST_ENV } from './Api';

const foodCategories =
    !TEST_ENV ?
        [{
            "id": 38,
            "category_name": "אלכוהול",
            "show": false,
            "created_at": "2021-08-04T12:11:30.000Z",
            "updated_at": "2021-08-04T12:30:18.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 22,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 39,
            "category_name": "אסיאתי",
            "show": false,
            "created_at": "2021-08-04T12:13:02.000Z",
            "updated_at": "2021-08-04T12:30:23.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 19,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 41,
            "category_name": "מאפייה",
            "show": false,
            "created_at": "2021-08-04T12:14:10.000Z",
            "updated_at": "2021-08-04T12:14:10.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 40,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 43,
            "category_name": "ארוחת בוקר",
            "show": false,
            "created_at": "2021-08-04T12:15:12.000Z",
            "updated_at": "2021-08-04T12:15:12.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 42,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 44,
            "category_name": "המבורגר",
            "show": false,
            "created_at": "2021-08-04T12:16:27.000Z",
            "updated_at": "2021-08-04T12:24:43.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 27,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 45,
            "category_name": "בית קפה",
            "show": false,
            "created_at": "2021-08-04T12:17:11.000Z",
            "updated_at": "2021-08-04T12:24:34.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 10,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 46,
            "category_name": "סיני",
            "show": false,
            "created_at": "2021-08-04T12:17:39.000Z",
            "updated_at": "2021-08-04T12:17:39.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 17,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 47,
            "category_name": "אתיופי",
            "show": false,
            "created_at": "2021-08-04T12:18:23.000Z",
            "updated_at": "2021-08-04T12:24:30.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 12,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 48,
            "category_name": "הודי",
            "show": false,
            "created_at": "2021-08-04T12:18:48.000Z",
            "updated_at": "2021-08-04T12:24:39.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 7,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 49,
            "category_name": "איטלקי",
            "show": false,
            "created_at": "2021-08-04T12:19:36.000Z",
            "updated_at": "2021-08-04T12:24:18.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 2,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 50,
            "category_name": "יפני",
            "show": false,
            "created_at": "2021-08-04T12:20:29.000Z",
            "updated_at": "2021-08-04T12:20:29.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 35,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 51,
            "category_name": "מקסיקני",
            "show": false,
            "created_at": "2021-08-04T12:21:05.000Z",
            "updated_at": "2021-08-04T12:24:54.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 6,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 52,
            "category_name": "ים תיכוני",
            "show": false,
            "created_at": "2021-08-04T12:22:57.000Z",
            "updated_at": "2021-08-04T12:24:49.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 8,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 53,
            "category_name": "פיצה",
            "show": true,
            "created_at": "2021-08-04T12:23:19.000Z",
            "updated_at": "2021-08-04T12:32:09.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 5,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 55,
            "category_name": "אוכל ביתי",
            "show": false,
            "created_at": "2021-08-04T12:27:08.000Z",
            "updated_at": "2021-08-04T12:28:50.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 54,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 57,
            "category_name": "חומוס",
            "show": false,
            "created_at": "2021-08-04T12:28:01.000Z",
            "updated_at": "2021-08-04T12:30:41.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 56,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 59,
            "category_name": "חנות נוחות",
            "show": false,
            "created_at": "2021-08-04T12:36:50.000Z",
            "updated_at": "2021-08-04T12:45:33.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 58,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 60,
            "category_name": "קינוח",
            "show": false,
            "created_at": "2021-08-04T12:37:32.000Z",
            "updated_at": "2021-08-04T12:46:09.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 9,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 62,
            "category_name": "אוכל עדות",
            "show": false,
            "created_at": "2021-08-04T12:39:02.000Z",
            "updated_at": "2021-08-04T12:43:45.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 61,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 64,
            "category_name": "גלידה",
            "show": false,
            "created_at": "2021-08-04T12:39:50.000Z",
            "updated_at": "2021-08-04T12:39:50.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 63,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 66,
            "category_name": "מיצים / שייקים",
            "show": false,
            "created_at": "2021-08-04T12:40:53.000Z",
            "updated_at": "2021-08-04T12:45:38.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 65,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 67,
            "category_name": "סלט",
            "show": false,
            "created_at": "2021-08-04T12:41:41.000Z",
            "updated_at": "2021-08-04T12:45:52.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 36,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 69,
            "category_name": "כריכים",
            "show": false,
            "created_at": "2021-08-04T12:42:15.000Z",
            "updated_at": "2021-08-04T12:45:30.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 68,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 70,
            "category_name": "סושי",
            "show": false,
            "created_at": "2021-08-04T12:42:35.000Z",
            "updated_at": "2021-08-04T12:45:26.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 34,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 71,
            "category_name": "תאילנדי",
            "show": false,
            "created_at": "2021-08-04T12:42:51.000Z",
            "updated_at": "2021-08-04T12:46:14.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 4,
                    "locale": "en"
                }
            ]
        },
        {
            "id": 73,
            "category_name": "פסטה",
            "show": false,
            "created_at": "2021-08-05T07:49:09.000Z",
            "updated_at": "2021-08-05T07:49:09.000Z",
            "categories": null,
            "legacy_id": null,
            "locale": "he-IL",
            "localizations": [
                {
                    "id": 72,
                    "locale": "en"
                }
            ]
        }
        ]
        :
        [
            {
                "id": 60,
                "category_name": "פסטה",
                "show": true,
                "created_at": "2021-06-28T09:13:34.000Z",
                "updated_at": "2021-06-28T09:13:34.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 77,
                "category_name": "חומוס",
                "show": true,
                "created_at": "2021-07-21T07:27:25.000Z",
                "updated_at": "2021-07-26T13:43:36.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 78,
                "category_name": "אסיאתי",
                "show": true,
                "created_at": "2021-07-25T13:24:59.000Z",
                "updated_at": "2021-07-25T14:49:07.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 79,
                "category_name": "מאפייה",
                "show": true,
                "created_at": "2021-07-25T13:26:19.000Z",
                "updated_at": "2021-07-26T13:44:20.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 80,
                "category_name": "ארוחת בוקר",
                "show": null,
                "created_at": "2021-07-25T13:26:58.000Z",
                "updated_at": "2021-07-26T13:39:29.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 81,
                "category_name": "המבורגר",
                "show": true,
                "created_at": "2021-07-25T13:30:10.000Z",
                "updated_at": "2021-07-26T13:41:53.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 82,
                "category_name": "בית קפה",
                "show": true,
                "created_at": "2021-07-25T13:30:37.000Z",
                "updated_at": "2021-07-26T13:40:49.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 83,
                "category_name": "סיני",
                "show": false,
                "created_at": "2021-07-25T13:31:03.000Z",
                "updated_at": "2021-07-25T13:31:03.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 84,
                "category_name": "חנות נוחות",
                "show": true,
                "created_at": "2021-07-25T13:31:19.000Z",
                "updated_at": "2021-07-25T13:31:19.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 85,
                "category_name": "קינוח",
                "show": true,
                "created_at": "2021-07-25T13:31:39.000Z",
                "updated_at": "2021-07-26T13:47:18.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 86,
                "category_name": "אתיופי",
                "show": true,
                "created_at": "2021-07-25T13:32:16.000Z",
                "updated_at": "2021-07-25T14:50:26.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 87,
                "category_name": "אוכל עדות",
                "show": null,
                "created_at": "2021-07-25T13:32:48.000Z",
                "updated_at": "2021-07-25T13:32:48.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 88,
                "category_name": "אוכל ביתי",
                "show": true,
                "created_at": "2021-07-25T13:33:15.000Z",
                "updated_at": "2021-07-26T06:48:04.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 89,
                "category_name": "גלידה",
                "show": true,
                "created_at": "2021-07-25T13:34:29.000Z",
                "updated_at": "2021-07-26T13:42:57.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 90,
                "category_name": "הודי",
                "show": true,
                "created_at": "2021-07-25T13:35:00.000Z",
                "updated_at": "2021-07-25T14:50:45.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 91,
                "category_name": "איטלקי",
                "show": true,
                "created_at": "2021-07-25T13:35:27.000Z",
                "updated_at": "2021-07-25T15:32:17.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 92,
                "category_name": "יפני",
                "show": false,
                "created_at": "2021-07-25T13:35:52.000Z",
                "updated_at": "2021-07-25T13:35:52.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 93,
                "category_name": "מיצים / שייקים",
                "show": true,
                "created_at": "2021-07-25T13:36:14.000Z",
                "updated_at": "2021-07-26T13:44:51.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 94,
                "category_name": "ים תיכוני",
                "show": true,
                "created_at": "2021-07-25T13:37:36.000Z",
                "updated_at": "2021-07-25T13:37:36.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 95,
                "category_name": "מקסיקני",
                "show": true,
                "created_at": "2021-07-25T13:37:58.000Z",
                "updated_at": "2021-07-25T14:53:40.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 96,
                "category_name": "פיצה",
                "show": true,
                "created_at": "2021-07-25T13:38:14.000Z",
                "updated_at": "2021-07-26T13:47:01.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 97,
                "category_name": "סלט",
                "show": false,
                "created_at": "2021-07-25T13:38:36.000Z",
                "updated_at": "2021-07-26T13:46:52.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 98,
                "category_name": "כריכים",
                "show": true,
                "created_at": "2021-07-25T13:38:56.000Z",
                "updated_at": "2021-07-25T13:38:56.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 99,
                "category_name": "סושי",
                "show": false,
                "created_at": "2021-07-25T13:39:16.000Z",
                "updated_at": "2021-07-25T13:39:16.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 100,
                "category_name": "תאילנדי",
                "show": true,
                "created_at": "2021-07-25T13:39:36.000Z",
                "updated_at": "2021-07-25T14:53:55.000Z",
                "categories": null,
                "locale": "he-IL"
            },
            {
                "id": 101,
                "category_name": "אלכוהול",
                "show": true,
                "created_at": "2021-08-04T12:44:30.000Z",
                "updated_at": "2021-08-04T12:44:30.000Z",
                "categories": null,
                "locale": "he-IL"
            }
        ]
            
        

export default foodCategories;