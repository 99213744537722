import React, { PureComponent } from "react";
import { DateFormat, DateTimeFormat, Ellipsis } from "../../resources/Filters";
import { Column, Row } from "../utils/Row";

export default class SupportListRow extends PureComponent {
	getAdminColumn(adminId) {
		const { admins } = this.props;

		const admin = admins.filter((a) => a.id * 1 === adminId * 1);
		if (admin && admin[0]) {
			return (
				<div className="badge" style={{ backgroundColor: admin[0].color ? admin[0].color : "#eee" }}>
					{admin[0].full_name}
				</div>
			);
		} else {
			return "";
		}
	}

	render() {
		const { data, openTicket } = this.props;

		if (!data) return "";

		const { id, content, subject, created, email, read, solved, typed_email, assigned_to, additional_details } = this.props.data;
		const { onSelectTicket } = this.props;

		const showSubject = subject && subject !== "Bug Report";

		return (
			<div onClick={() => onSelectTicket(id)} className={"list-group-item list-group-item-action" + (solved ? " list-group-item-light" : "") + (openTicket === id * 1 ? " active" : "")}>
				<Row>
					<Column autoCol={1}>
						{!solved ? <i className={"fa fa-circle " + (read ? "text-light" : "text-info")} /> : ""}
						{solved ? <i className={"fa fa-check text-info"} style={{ visibility: solved ? "visible" : "hidden" }} /> : ""}
					</Column>
					<Column className={openTicket ? "" : "col-md"}>
						<div className={read ? "" : "font-weight-bold"} style={{ textDecoration: solved ? "line-through" : "none" }}>
							{showSubject ? decodeURI(subject) : Ellipsis(content, openTicket ? 30 : 60)}
						</div>
					</Column>
					{!openTicket && (
						<Column className={"col-md small"}>
							{email} {typed_email && (!email || typed_email.toLowerCase().trim() !== email.toLowerCase().trim()) ? "(" + typed_email + ")" : ""}
						</Column>
					)}
					{!openTicket && assigned_to && (
						<Column autoCol={1}>
							<div className="badge">{this.getAdminColumn(assigned_to)}</div>
						</Column>
					)}
					<Column autoCol={1}>
						<small>{JSON.parse(decodeURI(additional_details)).phoneNumber && `date of birth: ${JSON.parse(decodeURI(additional_details)).dateOfBirth} | phone number: ${JSON.parse(decodeURI(additional_details)).phoneNumber}`}</small>
					</Column>
				</Row>
			</div>
		);
	}
}
