import { TEST_ENV } from './Api';

const tagList = !TEST_ENV ?

    [
        {
            "id": 2,
            "name": "Vegan"
        },
        {
            "id": 3,
            "name": "Certified"
        },
        {
            "id": 4,
            "name": "Wheelchair Accessible"
        },
        {
            "id": 5,
            "name": "Kosher"
        },
        {
            "id": 6,
            "name": "Animal Friendly"
        },
        {
            "id": 7,
            "name": "Gluten Free"
        },
        {
            "id": 8,
            "name": "Organic"
        }
    ] :

    [
        {
            "id": 1,
            "name": "Wheelchair Accessible",
            "restaurant": null,
            "published_at": "2021-07-12T11:55:53.000Z",
            "created_at": "2021-07-12T11:55:46.000Z",
            "updated_at": "2021-07-12T11:55:53.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 2,
            "name": "Gluten Free",
            "restaurant": null,
            "published_at": "2021-07-12T11:56:25.000Z",
            "created_at": "2021-07-12T11:56:21.000Z",
            "updated_at": "2021-07-12T11:56:25.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 3,
            "name": "Animal Friendly",
            "restaurant": null,
            "published_at": "2021-07-12T11:56:39.000Z",
            "created_at": "2021-07-12T11:56:38.000Z",
            "updated_at": "2021-07-12T12:02:09.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 4,
            "name": "Kosher",
            "restaurant": null,
            "published_at": "2021-07-12T11:56:49.000Z",
            "created_at": "2021-07-12T11:56:46.000Z",
            "updated_at": "2021-07-12T11:56:49.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 6,
            "name": "Shabbat",
            "restaurant": null,
            "published_at": "2021-07-12T11:57:15.000Z",
            "created_at": "2021-07-12T11:57:13.000Z",
            "updated_at": "2021-07-12T11:57:15.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 7,
            "name": "Certified",
            "restaurant": null,
            "published_at": "2021-07-12T11:57:31.000Z",
            "created_at": "2021-07-12T11:57:28.000Z",
            "updated_at": "2021-07-12T11:57:31.000Z",
            "chains": [],
            "restaurants": []
        },
        {
            "id": 8,
            "name": "Vegan",
            "restaurant": null,
            "published_at": "2021-07-12T11:58:04.000Z",
            "created_at": "2021-07-12T11:57:44.000Z",
            "updated_at": "2021-07-12T11:58:04.000Z",
            "chains": [],
            "restaurants": []
        }
    ]
export default tagList;