const menuCategories =
[
    {
        "id": "55",
        "title": "מנות ראשונות",
        "position": "1"
    },
    {
        "id": "56",
        "title": "בפיתה",
        "position": "20"
    },
    {
        "id": "57",
        "title": "מנות עיקריות",
        "position": "64"
    },
    {
        "id": "58",
        "title": "בלאפה",
        "position": "117"
    },
    {
        "id": "59",
        "title": "בצלחת",
        "position": "21"
    },
    {
        "id": "60",
        "title": "קינוחים",
        "position": "392"
    },
    {
        "id": "63",
        "title": "משהו נחמד",
        "position": "24"
    },
    {
        "id": "64",
        "title": "משהו טעים",
        "position": "23"
    },
    {
        "id": "66",
        "title": "ארוחות בוקר",
        "position": "3"
    },
    {
        "id": "67",
        "title": "מתוקים",
        "position": "363"
    },
    {
        "id": "70",
        "title": "מנות ילדים",
        "position": "25"
    },
    {
        "id": "71",
        "title": "סלטים",
        "position": "26"
    },
    {
        "id": "73",
        "title": "שייקים",
        "position": "28"
    },
    {
        "id": "74",
        "title": "חומוס",
        "position": "27"
    },
    {
        "id": "75",
        "title": "סושי",
        "position": "30"
    },
    {
        "id": "76",
        "title": "מוקפצים",
        "position": "187"
    },
    {
        "id": "77",
        "title": "מרקים",
        "position": "31"
    },
    {
        "id": "78",
        "title": "שיפודים",
        "position": "32"
    },
    {
        "id": "82",
        "title": "גלידה",
        "position": "349"
    },
    {
        "id": "85",
        "title": "ירקות חיים וצלויים",
        "position": "36"
    },
    {
        "id": "86",
        "title": "על הגריל",
        "position": "37"
    },
    {
        "id": "87",
        "title": "סירים ומחבתות",
        "position": "38"
    },
    {
        "id": "88",
        "title": "מאפים",
        "position": "39"
    },
    {
        "id": "89",
        "title": "תוספות",
        "position": "266"
    },
    {
        "id": "90",
        "title": "עוגות",
        "position": "40"
    },
    {
        "id": "91",
        "title": "מאפים מלוחים",
        "position": "42"
    },
    {
        "id": "94",
        "title": "עסקיות",
        "position": "22"
    },
    {
        "id": "95",
        "title": "מקפא",
        "position": "44"
    },
    {
        "id": "96",
        "title": "יוגורט",
        "position": "351"
    },
    {
        "id": "97",
        "title": "מנות עם פירות",
        "position": "45"
    },
    {
        "id": "99",
        "title": "מאפים ובצקים",
        "position": "46"
    },
    {
        "id": "100",
        "title": "גלידות וקפואים",
        "position": "314"
    },
    {
        "id": "101",
        "title": "מיצים",
        "position": "48"
    },
    {
        "id": "102",
        "title": "ממולאים",
        "position": "49"
    },
    {
        "id": "103",
        "title": "נקניקיות",
        "position": "50"
    },
    {
        "id": "104",
        "title": "מנות נוספות",
        "position": "116"
    },
    {
        "id": "105",
        "title": "איטלקי",
        "position": "58"
    },
    {
        "id": "106",
        "title": "תפריט אביב",
        "position": "51"
    },
    {
        "id": "107",
        "title": "קוקטיילים",
        "position": "52"
    },
    {
        "id": "108",
        "title": "דים סאם",
        "position": "53"
    },
    {
        "id": "109",
        "title": "מאקי",
        "position": "55"
    },
    {
        "id": "110",
        "title": "פוטומאקי",
        "position": "56"
    },
    {
        "id": "111",
        "title": "Inside Out",
        "position": "57"
    },
    {
        "id": "112",
        "title": "רולים",
        "position": "59"
    },
    {
        "id": "113",
        "title": "סנדוויץ' סושי",
        "position": "60"
    },
    {
        "id": "114",
        "title": "פאנקו",
        "position": "61"
    },
    {
        "id": "115",
        "title": "קומבינציות",
        "position": "63"
    },
    {
        "id": "116",
        "title": "מנות ראשונות וסלטים",
        "position": "19"
    },
    {
        "id": "117",
        "title": "בקטנה",
        "position": "65"
    },
    {
        "id": "119",
        "title": "בתוך הלחם",
        "position": "67"
    },
    {
        "id": "120",
        "title": "טורטיות",
        "position": "69"
    },
    {
        "id": "123",
        "title": "ארוחות ילדים",
        "position": "255"
    },
    {
        "id": "124",
        "title": "נודלס",
        "position": "72"
    },
    {
        "id": "125",
        "title": "מנות ווק",
        "position": "73"
    },
    {
        "id": "126",
        "title": "לחמים",
        "position": "74"
    },
    {
        "id": "127",
        "title": "פלאפל",
        "position": "75"
    },
    {
        "id": "128",
        "title": "קישים",
        "position": "76"
    },
    {
        "id": "130",
        "title": "קערות",
        "position": "77"
    },
    {
        "id": "131",
        "title": "מנות בפיתה ובצלחת",
        "position": "78"
    },
    {
        "id": "132",
        "title": "כריכים וסלטים",
        "position": "79"
    },
    {
        "id": "133",
        "title": "כריכים וטוסטים",
        "position": "80"
    },
    {
        "id": "134",
        "title": "משקאות ביתיים",
        "position": "81"
    },
    {
        "id": "135",
        "title": "בורקסים",
        "position": "82"
    },
    {
        "id": "136",
        "title": "מלאווח",
        "position": "83"
    },
    {
        "id": "137",
        "title": "אסייתי",
        "position": "85"
    },
    {
        "id": "138",
        "title": "שייקים ומיצים",
        "position": "347"
    },
    {
        "id": "142",
        "title": "טוסטים",
        "position": "86"
    },
    {
        "id": "144",
        "title": "מרקים וסלטים",
        "position": "87"
    },
    {
        "id": "145",
        "title": "כריכים",
        "position": "88"
    },
    {
        "id": "147",
        "title": "חמים וטעים",
        "position": "92"
    },
    {
        "id": "148",
        "title": "אידלי",
        "position": "94"
    },
    {
        "id": "149",
        "title": "אוטפאם",
        "position": "95"
    },
    {
        "id": "150",
        "title": "טאלי ולביבות",
        "position": "96"
    },
    {
        "id": "151",
        "title": "ממרחים",
        "position": "97"
    },
    {
        "id": "153",
        "title": "שייקים וקפואים",
        "position": "103"
    },
    {
        "id": "154",
        "title": "בריאות",
        "position": "98"
    },
    {
        "id": "155",
        "title": "סלטים ומנות צד",
        "position": "100"
    },
    {
        "id": "156",
        "title": "פיצות ומאפים",
        "position": "100"
    },
    {
        "id": "157",
        "title": "משקאות חמים",
        "position": "101"
    },
    {
        "id": "158",
        "title": "משקאות",
        "position": "99"
    },
    {
        "id": "159",
        "title": "אוכל הביתה",
        "position": "104"
    },
    {
        "id": "160",
        "title": "המבורגר",
        "position": "105"
    },
    {
        "id": "161",
        "title": "מנות קיץ",
        "position": "106"
    },
    {
        "id": "162",
        "title": "מגולגלים",
        "position": "107"
    },
    {
        "id": "163",
        "title": "ליד הבירה",
        "position": "68"
    },
    {
        "id": "165",
        "title": "תבשילים בחלב קוקוס",
        "position": "108"
    },
    {
        "id": "166",
        "title": "אטריות",
        "position": "109"
    },
    {
        "id": "167",
        "title": "סושי וספיישלים",
        "position": "111"
    },
    {
        "id": "171",
        "title": "פנקייקים",
        "position": "317"
    },
    {
        "id": "172",
        "title": "פיצה",
        "position": "112"
    },
    {
        "id": "173",
        "title": "חמות",
        "position": "113"
    },
    {
        "id": "176",
        "title": "טוסטים וגבינות",
        "position": "115"
    },
    {
        "id": "177",
        "title": "אמפנדס",
        "position": "121"
    },
    {
        "id": "178",
        "title": "רטבים וממרחים",
        "position": "120"
    },
    {
        "id": "180",
        "title": "ארוחות בוקר וראשונות",
        "position": "14"
    },
    {
        "id": "181",
        "title": "כריכים ופסטה",
        "position": "122"
    },
    {
        "id": "182",
        "title": "ארוחות בוקר וסלטים",
        "position": "4"
    },
    {
        "id": "183",
        "title": "יקיטורי מרקים וטמפורה",
        "position": "124"
    },
    {
        "id": "184",
        "title": "באן- לחמניות מאודות",
        "position": "123"
    },
    {
        "id": "185",
        "title": "פיצה ופסטות",
        "position": "126"
    },
    {
        "id": "186",
        "title": "ספרינג רול ויאטנמי וסלטים",
        "position": "127"
    },
    {
        "id": "187",
        "title": "ספרינג רול ויאטנמי ",
        "position": "130"
    },
    {
        "id": "188",
        "title": "מנות מוגשות על אינג'ירה",
        "position": "131"
    },
    {
        "id": "190",
        "title": "סלטים קטנים",
        "position": "132"
    },
    {
        "id": "191",
        "title": "גיוזה בהכנה ביתית ובאנ'ז",
        "position": "134"
    },
    {
        "id": "192",
        "title": "ראמן",
        "position": "135"
    },
    {
        "id": "195",
        "title": "מהווק וסלטים",
        "position": "137"
    },
    {
        "id": "196",
        "title": "לפינוק",
        "position": "136"
    },
    {
        "id": "197",
        "title": "באמצע",
        "position": "125"
    },
    {
        "id": "199",
        "title": "נחת בצלחת",
        "position": "139"
    },
    {
        "id": "201",
        "title": "כריכים ועיקריות",
        "position": "141"
    },
    {
        "id": "202",
        "title": "פאניני וסלטים",
        "position": "142"
    },
    {
        "id": "203",
        "title": "פיצה בתנור אבן",
        "position": "145"
    },
    {
        "id": "204",
        "title": "מיוחדים",
        "position": "146"
    },
    {
        "id": "206",
        "title": "מיידל'ה ביתי וכריכים",
        "position": "147"
    },
    {
        "id": "208",
        "title": "דים סאם ובאן",
        "position": "148"
    },
    {
        "id": "209",
        "title": "קלצונה ולזניות",
        "position": "149"
    },
    {
        "id": "212",
        "title": "המעדנייה",
        "position": "151"
    },
    {
        "id": "213",
        "title": "עלמא איטליה",
        "position": "152"
    },
    {
        "id": "215",
        "title": "המיוחדים",
        "position": "153"
    },
    {
        "id": "218",
        "title": "אורז",
        "position": "154"
    },
    {
        "id": "219",
        "title": "גלידות סורבה ",
        "position": "158"
    },
    {
        "id": "223",
        "title": "ירקות קלויים",
        "position": "159"
    },
    {
        "id": "224",
        "title": "מנות ",
        "position": "17"
    },
    {
        "id": "225",
        "title": "סושי בהרכבה",
        "position": "162"
    },
    {
        "id": "226",
        "title": "אלכוהול סחוט",
        "position": "157"
    },
    {
        "id": "228",
        "title": "יינות ",
        "position": "168"
    },
    {
        "id": "229",
        "title": "קלאס",
        "position": "169"
    },
    {
        "id": "230",
        "title": "על הלחם",
        "position": "360"
    },
    {
        "id": "231",
        "title": "קפואים",
        "position": "171"
    },
    {
        "id": "232",
        "title": "עוגות גדולות",
        "position": "356"
    },
    {
        "id": "234",
        "title": "עוגיות",
        "position": "172"
    },
    {
        "id": "235",
        "title": "שוקולד",
        "position": "362"
    },
    {
        "id": "236",
        "title": "מנות משתנות ",
        "position": "173"
    },
    {
        "id": "237",
        "title": "מאפים ועוגיות",
        "position": "372"
    },
    {
        "id": "238",
        "title": "עוגות בחושות בימי רביעי",
        "position": "174"
    },
    {
        "id": "241",
        "title": "MITZIPPY BOWLS",
        "position": "166"
    },
    {
        "id": "242",
        "title": "MITZIPPY SMOOTHIES",
        "position": "175"
    },
    {
        "id": "243",
        "title": "MITZIPPY COLDPRESSED JUICE",
        "position": "176"
    },
    {
        "id": "244",
        "title": "MITZIPPY SHOTS",
        "position": "177"
    },
    {
        "id": "245",
        "title": "MITZIPPY ALMONDS",
        "position": "178"
    },
    {
        "id": "246",
        "title": "ספיישלים משתנים",
        "position": "180"
    },
    {
        "id": "247",
        "title": "בנטו",
        "position": "181"
    },
    {
        "id": "248",
        "title": "מרקים ומנות עיקריות",
        "position": "182"
    },
    {
        "id": "249",
        "title": "פלוס",
        "position": "184"
    },
    {
        "id": "250",
        "title": "פסטה",
        "position": "185"
    },
    {
        "id": "252",
        "title": "שקשוקות",
        "position": "188"
    },
    {
        "id": "253",
        "title": "שקשוקה וחומוס",
        "position": "189"
    },
    {
        "id": "256",
        "title": "מלוח",
        "position": "190"
    },
    {
        "id": "259",
        "title": "ווק",
        "position": "194"
    },
    {
        "id": "260",
        "title": "שניצל בבאגט",
        "position": "195"
    },
    {
        "id": "261",
        "title": "שניצל בצלחת",
        "position": "196"
    },
    {
        "id": "262",
        "title": "שניצל טבעוני בבאגט",
        "position": "253"
    },
    {
        "id": "270",
        "title": "מחירים",
        "position": "0"
    },
    {
        "id": "272",
        "title": "ספיישלים יומיים",
        "position": "256"
    },
    {
        "id": "277",
        "title": "סמבוסק",
        "position": "258"
    },
    {
        "id": "307",
        "title": "מלוואחים",
        "position": "260"
    },
    {
        "id": "309",
        "title": "תבשילים בימי ב-ה ",
        "position": "261"
    },
    {
        "id": "310",
        "title": "תבשילים ",
        "position": "262"
    },
    {
        "id": "314",
        "title": "רטבים",
        "position": "263"
    },
    {
        "id": "339",
        "title": "סלטים מרקים וקינוחים",
        "position": "265"
    },
    {
        "id": "347",
        "title": "סלטים וקינוחים",
        "position": "310"
    },
    {
        "id": "349",
        "title": "סלטים ומרקים",
        "position": "309"
    },
    {
        "id": "351",
        "title": "מרקים וקינוחים",
        "position": "364"
    },
    {
        "id": "356",
        "title": "ספיישלים",
        "position": "62"
    },
    {
        "id": "366",
        "title": "מגשי מסיבה",
        "position": "267"
    },
    {
        "id": "368",
        "title": "מרק היום",
        "position": "268"
    },
    {
        "id": "369",
        "title": "מנות שכיף לחלוק",
        "position": "271"
    },
    {
        "id": "372",
        "title": "מסבחה ואורז",
        "position": "320"
    },
    {
        "id": "373",
        "title": "בחורף",
        "position": "193"
    },
    {
        "id": "374",
        "title": "ראשון",
        "position": "365"
    },
    {
        "id": "375",
        "title": "שני",
        "position": "366"
    },
    {
        "id": "377",
        "title": "שלישי",
        "position": "367"
    },
    {
        "id": "378",
        "title": "רביעי",
        "position": "368"
    },
    {
        "id": "379",
        "title": "חמישי",
        "position": "369"
    },
    {
        "id": "380",
        "title": "עוד על הפלנצ'ה",
        "position": "459"
    },
    {
        "id": "381",
        "title": "בטורטיה או לחמניה",
        "position": "370"
    },
    {
        "id": "382",
        "title": "שניצל 100%",
        "position": "361"
    },
    {
        "id": "384",
        "title": "מאזטים",
        "position": "373"
    },
    {
        "id": "385",
        "title": "מנות סלט",
        "position": "374"
    },
    {
        "id": "386",
        "title": "מהטאבון",
        "position": "375"
    },
    {
        "id": "387",
        "title": "מנות חמות",
        "position": "376"
    },
    {
        "id": "388",
        "title": "מנות חמות",
        "position": "377"
    },
    {
        "id": "389",
        "title": "פרטים אודות מתחם התחנה",
        "position": "378"
    },
    {
        "id": "390",
        "title": "הוסומאקי (סושי) ",
        "position": "379"
    },
    {
        "id": "391",
        "title": "וראמאקי (אינסייד-אאוט)",
        "position": "380"
    },
    {
        "id": "392",
        "title": "מנות על בסיס \"ביצת עין\"  טבעונית",
        "position": "381"
    },
    {
        "id": "393",
        "title": "אוראמאקי (אינסייד-אאוט)",
        "position": "382"
    },
    {
        "id": "394",
        "title": "פרי שייק",
        "position": "383"
    },
    {
        "id": "395",
        "title": "קערת בריאות",
        "position": "384"
    },
    {
        "id": "396",
        "title": "קערת אסאי",
        "position": "385"
    },
    {
        "id": "397",
        "title": "תרופות הסבתא של טבעל'ה",
        "position": "386"
    },
    {
        "id": "398",
        "title": "הודי",
        "position": "387"
    },
    {
        "id": "399",
        "title": "ויאטנאמי",
        "position": "388"
    },
    {
        "id": "400",
        "title": "קוריאה",
        "position": "389"
    },
    {
        "id": "401",
        "title": "יפני",
        "position": "390"
    },
    {
        "id": "402",
        "title": "תאילנדי",
        "position": "391"
    },
    {
        "id": "403",
        "title": "ACAI BOWLS",
        "position": "393"
    },
    {
        "id": "404",
        "title": "SMOOTHIES",
        "position": "394"
    },
    {
        "id": "405",
        "title": "VEGGIE BOWLS",
        "position": "395"
    },
    {
        "id": "406",
        "title": "SQUEEZED",
        "position": "396"
    },
    {
        "id": "407",
        "title": "פסטה בהרכבה",
        "position": "397"
    },
    {
        "id": "408",
        "title": "פסטה ספיישל",
        "position": "186"
    },
    {
        "id": "409",
        "title": "אנטיפסטי",
        "position": "398"
    },
    {
        "id": "410",
        "title": "מוצרים",
        "position": "399"
    },
    {
        "id": "411",
        "title": "חמוצים",
        "position": "357"
    },
    {
        "id": "412",
        "title": "תפריט חורף",
        "position": "400"
    },
    {
        "id": "413",
        "title": "תפריט קיץ",
        "position": "401"
    },
    {
        "id": "414",
        "title": "תפריט קיץ",
        "position": "402"
    },
    {
        "id": "415",
        "title": "מאצ'ה- ",
        "position": "403"
    },
    {
        "id": "416",
        "title": "B-BOWL",
        "position": "404"
    },
    {
        "id": "417",
        "title": "B-BOOST",
        "position": "405"
    },
    {
        "id": "418",
        "title": "B-SMOOTHIE",
        "position": "406"
    },
    {
        "id": "419",
        "title": "B-FRESH JUICE",
        "position": "407"
    },
    {
        "id": "420",
        "title": "B-FREEZE",
        "position": "408"
    },
    {
        "id": "421",
        "title": "B-BUBBLE TEA",
        "position": "409"
    },
    {
        "id": "422",
        "title": "פרלינים",
        "position": "410"
    },
    {
        "id": "423",
        "title": "טבלאות שוקולד",
        "position": "411"
    },
    {
        "id": "424",
        "title": "שוקולדים ומוצרים נוספים",
        "position": "412"
    },
    {
        "id": "425",
        "title": "קטגוריה חדשה",
        "position": "413"
    },
    {
        "id": "426",
        "title": "ירקניה",
        "position": "414"
    },
    {
        "id": "427",
        "title": "בלהבלה",
        "position": "415"
    },
    {
        "id": "428",
        "title": "משקאות מבריקים",
        "position": "416"
    },
    {
        "id": "429",
        "title": "מנות לדרך",
        "position": "417"
    },
    {
        "id": "430",
        "title": "קינוחים עם גלידה",
        "position": "418"
    },
    {
        "id": "431",
        "title": "מטבח",
        "position": "419"
    },
    {
        "id": "432",
        "title": "בראנץ שישי",
        "position": "420"
    },
    {
        "id": "433",
        "title": "בראנץ' שישי",
        "position": "421"
    },
    {
        "id": "434",
        "title": "קלצונה",
        "position": "422"
    },
    {
        "id": "435",
        "title": "מאפים וכריכים",
        "position": "423"
    },
    {
        "id": "436",
        "title": "קלצונה",
        "position": "424"
    },
    {
        "id": "437",
        "title": "חוץ מחומוס",
        "position": "425"
    },
    {
        "id": "438",
        "title": "דוסה",
        "position": "426"
    },
    {
        "id": "439",
        "title": "לזניה",
        "position": "427"
    },
    {
        "id": "440",
        "title": "הרכבה אישית",
        "position": "428"
    },
    {
        "id": "441",
        "title": "קערה בהרכבה אישית",
        "position": "429"
    },
    {
        "id": "442",
        "title": "מאפים וקישים",
        "position": "430"
    },
    {
        "id": "443",
        "title": "עוגות חגיגיות",
        "position": "431"
    },
    {
        "id": "444",
        "title": "קינוחים אישיים",
        "position": "432"
    },
    {
        "id": "445",
        "title": "גבינות וממרחים",
        "position": "433"
    },
    {
        "id": "446",
        "title": "מאפים ומוצרים",
        "position": "434"
    },
    {
        "id": "447",
        "title": "וופל בלגי",
        "position": "435"
    },
    {
        "id": "448",
        "title": "מנות בקטנה",
        "position": "436"
    },
    {
        "id": "449",
        "title": "בורגרים בענק",
        "position": "437"
    },
    {
        "id": "450",
        "title": "מנות בקערה",
        "position": "438"
    },
    {
        "id": "452",
        "title": "בשרים מהצומח",
        "position": "440"
    },
    {
        "id": "454",
        "title": "חומוס של ענקים",
        "position": "442"
    },
    {
        "id": "455",
        "title": "טאקוס",
        "position": "443"
    },
    {
        "id": "456",
        "title": "טאקוס",
        "position": "444"
    },
    {
        "id": "457",
        "title": "קסדיות",
        "position": "445"
    },
    {
        "id": "458",
        "title": "בוריטוס",
        "position": "446"
    },
    {
        "id": "459",
        "title": "במשקל",
        "position": "447"
    },
    {
        "id": "460",
        "title": "במשקל",
        "position": "448"
    },
    {
        "id": "462",
        "title": " בוב בורגר",
        "position": "449"
    },
    {
        "id": "463",
        "title": "בוב מארלי (באגט)",
        "position": "450"
    },
    {
        "id": "464",
        "title": "בוב הבריא",
        "position": "451"
    },
    {
        "id": "465",
        "title": "בוב במאנצ'",
        "position": "452"
    },
    {
        "id": "466",
        "title": "בוב ספוג (בפיתה)",
        "position": "453"
    },
    {
        "id": "467",
        "title": "בוב הבנאי (טורטייה)",
        "position": "454"
    },
    {
        "id": "468",
        "title": "בוב ספוג (בפיתה)",
        "position": "453"
    },
    {
        "id": "469",
        "title": "סנדוויצים",
        "position": "455"
    },
    {
        "id": "470",
        "title": "טעמים מיוחדים של פעם",
        "position": "456"
    },
    {
        "id": "471",
        "title": "קפה + כריכון קטן",
        "position": "457"
    },
    {
        "id": "472",
        "title": "חומוס - זמנית אין",
        "position": "458"
    },
    {
        "id": "473",
        "title": "לוהט בענק",
        "position": "170"
    },
    {
        "id": "474",
        "title": "בקטע של המבורגר",
        "position": "460"
    },
    {
        "id": "475",
        "title": "הסנדוויץ' של הענק",
        "position": "461"
    },
    {
        "id": "476",
        "title": "הענק הירוק להכנה ביתית",
        "position": "462"
    },
    {
        "id": "477",
        "title": "הסנדוויץ' של הענק 2",
        "position": "463"
    },
    {
        "id": "479",
        "title": "אקסטרה לסנדוויץ׳/בורגר",
        "position": "465"
    },
    {
        "id": "480",
        "title": "התוספות שלנו",
        "position": "466"
    }
]

export default menuCategories;