import React, {Component} from 'react';

const SUBDIVISIONS = 16;
const INTERVAL = 20;

function makeColorGradient(frequency1, frequency2, frequency3,phase1, phase2, phase3,center = 128, width = 127, len = SUBDIVISIONS) {
	let res = [];
	for (let i = 0; i < len; ++i) {
		const red = Math.floor(Math.sin(frequency1*i + phase1) * width + center);
		const grn = Math.floor(Math.sin(frequency2*i + phase2) * width + center);
		const blu = Math.floor(Math.sin(frequency3*i + phase3) * width + center);
		res.push([red,grn,blu]);
	}
	return res;
}

export default class Loading extends Component {

	constructor() {
		super();

		this.state = {
			f : 0.3,
			pr : 0,
			pg : 2,
			pb : 4
		}
	}

	componentDidMount() {
		this.interval = setInterval(this.changeFreq.bind(this), INTERVAL);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	changeFreq() {
		this.setState({
			pr : this.state.pr + 0.201,
			pg : this.state.pg + 0.199,
			pb : this.state.pb + 0.200,
		})
	}

	render() {
		const f = 0.15, p = { r: this.state.pr, g : this.state.pg, b : this.state.pb },
			subs = makeColorGradient(f,f,f,p.r,p.g,p.b);

		return (
			<div className='loading-bar font-weight-bold'>
				{subs.map((sub, index) => <b key={index} style={{backgroundColor: 'rgb('+sub[0]+','+sub[1]+','+sub[2]+')' }} />)}
			</div>
		);
	}
}