import React, {Component} from 'react';
import Text from "../utils/Text";
import Button from "../utils/Button";

export default class LoginBox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading : false,
			username : '',
			password : '',
		}
	}

	componentDidMount() {
		this.setState({
			username : '',
			password : '',
		});
		
	}

	doLogin = async () => {
		this.props.doLogin(this.state.username, this.state.password);
	};

	_onLoginClick = () => {
		this.doLogin();
	};

	render() {

		const {loading} = this.state;
		const {loggedIn, error} = this.props;
		const cardClass = (loggedIn) ?'border-success':(error)?'border-danger':'';

		return (
			<div>
				{loading?
					<div className="login-form">
						...
					</div>
				:
					<div>
					{loggedIn ? '' :
					<div className={'login-form card '+cardClass}>
						<div className="card-body">
							<form onSubmit={this._onLoginClick}>
								{error && <div className="alert alert-danger">{error}</div>}
								<h2><Text>admin.adminLogin</Text></h2>
								<div className="form-group">
									<input type="text" className={'form-control'} value={this.state.username} onChange={e => this.setState({username : e.target.value})} />
								</div>
								<div className="form-group">
									<input type="password" className={'form-control'} value={this.state.password} onChange={e => this.setState({password : e.target.value})} />
								</div>
								<div className={'form-group'}>
									<Button type="submit" primary={1} onClick={this._onLoginClick}><Text>login.logIn</Text></Button>
								</div>
							</form>
						</div>
					</div>
				}
			</div>
			}
			</div>
		)
	}

}