import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import ReviewRow from "../components/Reviews/ReviewRow";
import { Api } from "../resources/Api";
import Multiselect from 'multiselect-react-dropdown';
import Button from '../components/utils/Button';
import ObjectHandler from '../resources/objectHandler';
import foodCategories from '../resources/food-categories';


class MigrationScript extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ilRestaurants: null,
			ilChains: null,
			ilRestaurantsAll: null,
			selectedRestaurants: null,
			selectedChains: null,
			selectedValue: null,
			currentRestaurantName: '',
			inProgress: false,
			error: null,
			ukChains: null,
			ukRestaurants: null
		}
	}

	componentDidMount = async () => {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);


		const ukChains = await Api.getChains();
		const ukRestaurants = await Api.getRestaurants();


		let ilRestaurantsAll = await Api.getIlRestaurants();
		let ilChains = this.getChainList(ilRestaurantsAll).filter(chain => chain.active === 1 && !ukChains.map(ukChain => ukChain.legacy_id + "").includes(chain.restaurant_id));		
		let ilRestaurants = [...ilRestaurantsAll.filter(rest => !ilChains.map(chain => chain.restaurant_id).includes(rest.restaurant_id))]
		ilRestaurants = ilRestaurants.filter(rest => !ukRestaurants.map(ukRest => ukRest.legacy_id + "").includes(rest.branch_id))

		this.setState({ ilRestaurants, ilChains, ukRestaurants, ukChains, ilRestaurantsAll })

	}

	getChainList = (restaurants) => {
		const groupById = this.groupBy("restaurant_id");
		const groupedById = groupById(restaurants)

		const chainIds = [...Object.keys(groupedById)]//.filter(chain => chain.length > 3)];
		const chainList = [];

		for (let i of chainIds) {
			if (groupedById[i].length > 1) {
				chainList.push({...groupedById[i][0], display_name: `${groupedById[i][0].restaurant_name} (${groupedById[i].length})`})
			}
		}

		return chainList;
	}

	groupBy = (key) => {
		return function group(array) {
			return array.reduce((acc, obj) => {
				const property = obj[key];
				acc[property] = acc[property] || [];
				acc[property].push(obj);
				return acc;
			}, {});
		};
	}

	onSelectChain(selectedList, selectedItem) {
		this.setState({ selectedChains: selectedList })
	}

	onRemoveChain(selectedList, removedItem) {
		this.setState({ selectedChains: selectedList })

	}

	onSelectRestaurant(selectedList, selectedItem) {
		this.setState({ selectedRestaurants: selectedList })
	}

	onRemoveRestaurant(selectedList, removedItem) {
		this.setState({ selectedRestaurants: selectedList })
	}

	async onSubmitChain() {
		this.setState({ inProgress: true })
		const { selectedChains, ukChains, ilRestaurantsAll } = this.state;

		const chainObjects = await ObjectHandler.getChainObjects(selectedChains);

		for (let chain of chainObjects) {
			this.setState({ currentChainName: chain.name })
			const submittedChain = await Api.submitChain(chain);
			if (submittedChain) {
				this.setState({ currentChainName: 'finished with ' + submittedChain.name })
				const restaurants = ilRestaurantsAll.filter(rest => rest.restaurant_id == chain.legacy_id + "")
				console.log(`${chain.name}	to add  ${restaurants.length}`)// to ${submittedChain.name}`)
				this.onSubmitRestaurant(restaurants)

			}
		}
	}

	async onSubmitRestaurant(restaurants) {
		this.setState({ inProgress: true })

		const ukChains = await Api.getChains();

		for (let restaurantItem of restaurants) {
			this.setState({ currentRestaurantName: restaurantItem.restaurant_name })
			let restaurant = await Api.getIlRestaurantData(restaurantItem.branch_id);
			restaurant = { ...restaurant, ...restaurantItem}
			const restaurantObject = await ObjectHandler.getRestaurantObject(restaurant, ukChains, foodCategories)
			const submittedRestaurantObject = await Api.submitRestaurant(restaurantObject);


			if (submittedRestaurantObject) {
				let reviewItems = await ObjectHandler.getReviewsObjectForRestaurant(restaurant.restaurant_id, restaurant.branch_id, submittedRestaurantObject.id)


				await Api.submitReviewsForRestaurant(reviewItems);
			}

			else {
				this.setState({ error: 'couאני ld not upload restaurant' })
			} 
		}
		this.setState({ inProgress: false, currentRestaurantName: 'Finished!' })

	}

	render() {

		const { login } = this.props;
		const { ilRestaurants, ilChains, selectedRestaurants, selectedChains, currentRestaurantName, currentChainName, inProgress, error, ukRestaurants, ukChains } = this.state;

		return (
			<div>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							{!ilRestaurants && <h5 className="card-title text-secondary"><Text>migration.loading</Text></h5>}
							{ilRestaurants &&
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h5 className="card-title text-secondary"><Text>migration.numberOfRestaurants</Text><Text>{ilRestaurants.length}</Text></h5>

									<Multiselect
										options={this.state.ilRestaurants} // Options to display in the dropdown
										selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
										onSelect={this.onSelectRestaurant.bind(this)} // Function will trigger on select event
										onRemove={this.onRemoveChain.bind(this)} // Function will trigger on remove event
										displayValue="restaurant_name" // Property name to display in the dropdown options
										placeholder={selectedRestaurants && selectedRestaurants.length > 0 ? 'עוד' : 'בחרי סניף'}	
										style= {{chips: { background: "green" }, searchBox: { textAlign: 'right', border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" }}}									
									/>
									{selectedRestaurants && <Text>{`Selected ${selectedRestaurants.length} restaurants`}</Text>}
									<br />
									{<div><Text>{currentRestaurantName.length > 0 ? `Adding ${currentRestaurantName}` : `...`}</Text></div>}
									{error && <div><Text>{error}</Text></div>}

									<Button disabled={inProgress || (!selectedRestaurants || selectedRestaurants.length === 0)} success={1} onClick={() => selectedRestaurants && selectedRestaurants.length > 0 ? this.onSubmitRestaurant(selectedRestaurants) : null}><Icon>yes</Icon><Text>migration.startUpload</Text></Button>
								</div>}
							{ilChains &&
								<div style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
									<h5 className="card-title text-secondary"><Text>migration.numberOfChains</Text><Text>{': ' + ilChains.length}</Text></h5>

									<Multiselect
										options={this.state.ilChains} // Options to display in the dropdown
										selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
										onSelect={this.onSelectChain.bind(this)} // Function will trigger on select event
										onRemove={this.onRemoveChain.bind(this)} // Function will trigger on remove event
										displayValue="display_name" // Property name to display in the dropdown options
										placeholder={selectedRestaurants && selectedRestaurants.length > 0 ? 'עוד' : 'בחרי רשת'}	
										style= {{chips: { background: "green" }, searchBox: { textAlign: 'right', border: "none", "borderBottom": "1px solid blue", "border-radius": "0px" }}}
									/>
									{selectedChains && <Text>{`Selected ${selectedChains.length} chains`}</Text>}
									<br />
									{currentChainName && <div><Text>{currentChainName.length > 0 ? `Adding ${currentChainName}` : `...`}</Text></div>}
									{error && <div><Text>{error}</Text></div>}

									<Button disabled={inProgress || (!selectedChains || selectedChains.length === 0)} success={1} onClick={() => selectedChains && selectedChains.length > 0 ? this.onSubmitChain() : null}><Icon>yes</Icon><Text>migration.startUpload</Text></Button>



								</div>

							}
						</div>


					</div>}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(MigrationScript);