import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../actions/loginActions';
import * as menusActions from '../actions/menusActions';
import LoginBox from '../components/login/LoginBox';
import Button from '../components/utils/Button';
import Confirmation from '../components/utils/Confirmation';
import Icon from '../components/utils/Icon';
import LineInput from '../components/utils/LineInput';
import Text, { Xlate } from '../components/utils/Text';
import { IS_US, TEST_ENV } from '../resources/Api';

class Intro extends Component {
	constructor() {
		super();
		this.state = {
			reloading: false,
		};
	}

	toggleReloading = () => this.setState({ reloading: !this.state.reloading });

	onReloadRestaurants = () => {
		const { reloadRestaurants } = this.props.menusActions;
		reloadRestaurants();
	};

	render() {
		const { loginActions, login, labelling, migration, reviews, support, contact, menus, users } =
			this.props;
		const numOfCompanies = labelling.companies ? labelling.companies.length : 0;
		const numOfVfServiceProviders = migration.vfServiceProviders
			? migration.vfServiceProviders.length
			: 0;
		const numOfVfBusinesses = migration.vfBusinesses ? migration.vfBusinesses.length : 0;

		const contactTickets = contact.tickets ? contact.tickets.length : 0;
		const unreadContactTickets = contact.tickets
			? contact.tickets.filter((ticket) => !ticket.read).length
			: 0;

		const reviewsIconCls =
			reviews.pendingReviews.length >= 50
				? 'text-danger'
				: reviews.pendingReviews.length >= 10
					? 'text-warning'
					: 'text-secondary';
		const reviewsTextCls =
			reviews.pendingReviews.length >= 50
				? 'text-danger font-weight-bold'
				: reviews.pendingReviews.length >= 10
					? 'text-danger'
					: 'text-secondary';

		const { userList } = users;

		return (
			<div>
				{ !login.loading && (
					<LoginBox
						doLogin={ loginActions.login }
						credentials={ login.credentials }
						loggedIn={ login.loggedIn }
						error={ login.loginError }
					/>
				) }

				{ login.loggedIn && (
					<div>
						<div className={ 'alert ' + (TEST_ENV ? 'alert-danger' : 'alert-info') }>
							<b>
								<Text>{ !TEST_ENV ? 'greetings.welcome' : 'greetings.testEnvironment' }</Text>
							</b>{ ' ' }
							<Text>{ 'greetings.welcomeText' } </Text> <Text>migration.version</Text>
							<br />
							{ TEST_ENV && <Text>{ 'greetings.testEnvironmentText' }</Text> }
						</div>
						<div className="card-deck mt-3 bg-light rounded px-2 py-4">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-secondary">
										<Text>sections.reviews</Text>
									</h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">
										{ reviews.pendingReviews.length > 0 && (
											<Icon className={ 'mx-2 ' + reviewsIconCls }>exclamation-triangle</Icon>
										) }
										<span className={ reviewsTextCls }>
											<Text num={ reviews.pendingReviews.length }>reviews.numPendingReviews</Text>
										</span>
									</li>
								</ul>
								<div className="card-footer">
									<Button to="/reviews" secondary={ 1 } block={ 1 }>
										<Text>admin.manageReviews</Text>
									</Button>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-secondary">
										<Text>sections.restaurants</Text>
									</h5>
								</div>
								<div className="card-footer">
									<Button to="/restaurants" secondary={ 1 } block={ 1 }>
										<Text>admin.manageRestaurants</Text>
									</Button>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-secondary">
										<Text>sections.businesses</Text>
									</h5>
								</div>
								<div className="card-footer">
									<Button to="/businesses" secondary={ 1 } block={ 1 }>
										<Text>admin.manageBusinesses</Text>
									</Button>
								</div>
							</div>
							{ !IS_US && (
								<div className={ 'card' }>
									<div className="card-body">
										<h5 className="card-title text-danger">
											<Text>migration.productLabelling</Text>
										</h5>
									</div>
									<ul className="list-group list-group-flush">
										<li className="list-group-item">
											<span className={ 'text-info' }>
												<Icon className="mx-2">building</Icon>
												<Text num={ numOfCompanies }>labelling.numOfCompanies</Text>
											</span>
										</li>
									</ul>
									<div className="card-footer">
										<Button to="/productLabelling" block={ 1 } className="btn-danger">
											<Text>migration.migrateRestaurants</Text>
										</Button>
									</div>
								</div>
							) }


							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-info">
										<Text>sections.contact</Text>
									</h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">
										<span
											className={
												unreadContactTickets > 0 ? 'text-danger font-weight-bold' : 'text-info'
											}
										>
											<Icon className="mx-2">envelope</Icon>
											<Text num={ unreadContactTickets }>contact.numUnreadMessages</Text>
										</span>
									</li>
									<li className="list-group-item">
										<Icon className="text-secondary mx-2">clipboard-list</Icon>
										<Text num={ contactTickets }>contact.numTotalTickets</Text>
									</li>
								</ul>
								<div className="card-footer">
									<Button to="/contact" info={ 1 } block={ 1 }>
										<Text>admin.manageContact</Text>
									</Button>
								</div>
							</div>

							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-primary">
										<Text>Push Notifications</Text>
									</h5>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">
										<Icon className="text-primary mx-2">frown</Icon>
										<Text num={ userList.length }>users.totalUsers</Text>
									</li>
								</ul>
								<div className="card-footer">
									<Button to="/users" primary={ 1 } block={ 1 }>
										<Text>admin.manageUsers</Text>
									</Button>
								</div>
							</div>
							{
								<div className="card">
									<div className="card-body">
										<h5 className="card-title text-primary">
											<Text>sections.cacheManagement</Text>
										</h5>
									</div>
									<div className="card-footer">
										<Button to="/cache-management" primary={ 1 } block={ 1 }>
											<Text>sections.cacheManagement</Text>
										</Button>
									</div>
								</div>
							}
							<div className="card">
								<div className="card-body">
									<h5 className="card-title text-primary">
										<Text>sections.suggested-entities</Text>
									</h5>
								</div>
								<div className="card-footer">
									<Button to="/suggested-entities" primary={ 1 } block={ 1 }>
										<Text>sections.suggested-entities</Text>
									</Button>
								</div>
							</div>

						</div>
					</div>
				) }
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.login,
		reviews: state.reviews,
		support: state.support,
		contact: state.contact,
		menus: state.menus,
		users: state.users,
		labelling: state.labelling,
		migration: state.migration,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
		menusActions: bindActionCreators({ ...menusActions }, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
