import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as adminsActions from '../actions/adminsActions';
import Text, {Xlate} from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import AdminRow from "../components/admins/AdminRow";
import RestaurantMenuItemAdd from "../components/menus/RestaurantMenuItemAdd";
import Button from "../components/utils/Button";
import {Column, Row} from "../components/utils/Row";
import AdminAddRow from "../components/admins/AdminAddRow";
import LineInput from "../components/utils/LineInput";
import Confirmation from "../components/utils/Confirmation";

class Admins extends Component {

	constructor(props) {
		super(props);
		this.state = {
			adding : false,
			generatingHash : false,
			hash : ''
		}
	}

	onGenerateHash = async (hash) => {

		if ((hash+"").length < 1) return;

		const {generateHash} = this.props.adminsActions;
		const res = await generateHash(hash);

		this.setState({
			generatingHash: false,
			hash : res.hash
		});
	};


	onAddAdmin = (username, password, full_name) => {
		const {addAdmin} = this.props.adminsActions;
		addAdmin(username, password, full_name);
	};

	onEditAdmin = (adminId, username, password) => {
		const {editAdmin} = this.props.adminsActions;
		editAdmin(adminId, username, password);
	};

	onDeleteAdmin = async(adminId) => {
		const {deleteAdmin, getAdminUsers} = this.props.adminsActions;

		await deleteAdmin(adminId);
		getAdminUsers();
	};

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn || !this.props.login.superAdmin) {
				this.props.history.push('/');
			}
		}, 500);
	}

	toggleAddingItem = () => this.setState({adding : !this.state.adding});
	toggleGenerateHash = () => this.setState({generatingHash : !this.state.generatingHash});

	render() {

		const {login, adminsActions} = this.props;
		const {adminsList} = this.props.admins;
		const {adding, generatingHash, hash} = this.state;
		return (
			<Row>
				<Column className="col-md-8">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title text-secondary"><Text>sections.admins</Text></h5>
							{adding ?
								<AdminAddRow onDismiss={this.toggleAddingItem} onAddAdmin={this.onAddAdmin}/>:
								<div>
									<Button small={1} primary={1} onClick={this.toggleAddingItem}><Text>admins.addAdmin</Text></Button>
								</div>
							}
						</div>
						{adminsList.map(admin => <AdminRow login={login} key={admin.id} admin={admin} onDeleteAdmin={this.onDeleteAdmin} />)}
					</div>
				</Column>
				<Column className="col-md-4">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title text-primary"><Text>admin.actions</Text></h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								{hash && <pre>{hash}</pre>}
								{generatingHash?
									<LineInput onSubmit={this.onGenerateHash} buttonLabel={Xlate("actions.generate")} onDismiss={this.toggleGenerateHash} />
									:<Button primary={1} block={1} onClick={this.toggleGenerateHash}><Text>admin.generateHash</Text></Button>}
							</li>
						</ul>
					</div>
				</Column>
			</Row>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, admins: state.admins }
};

const mapDispatchToProps = dispatch => {
	return {
		adminsActions: bindActionCreators({...adminsActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Admins);