import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as labellingActions from '../actions/labellingActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import { Api } from "../resources/Api";
import Multiselect from 'multiselect-react-dropdown';
import Button from '../components/utils/Button';

import * as XLSX from 'xlsx';
import AgGridComponent from "../components/Restaurants/AgGridComponent";
import { saveAs } from 'file-saver';


class ProductLabelling extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCompanies: [],
			result: null,
			excelLines: [],
			products: [],
			ingredientsSeparated: false,
			fileDownloadUrl: null,
			chosen1GsCompanyId: null,
			gs1Products: null,
			gs1ColumnDefs: []
		}
	}

	componentDidMount = async () => {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		const columnDefs = [
			{ field: "gtin", filter: true },
			{ field: "product_name", filter: true, sortable: true },
			{ field: "is_vegan", filter: true, sortable: true },
			{ field: "is_vegan_friendly", filter: true, sortable: true }]


		this.setState({ columnDefs });

	}

	onSelectCompany(company) {
		const { selectedCompanies } = this.state
		this.setState({ selectedCompanies: company, ...selectedCompanies })
	}

	onRemoveCompany(company) {
		const { selectedCompanies } = this.state

		this.setState({ selectedCompanies: company, ...selectedCompanies.filter(c => c.id !== company.id) })
	}

	getIngredientStatus = (status, isProd) => {
		if (!status) return null;

		if (['vegan', 'טבעוני', 'VEGAN'].includes(status.toLowerCase())) return isProd ? 'approved' : 'vegan';
		else if (['failed', 'FAILED', 'לא טבעוני'].includes(status.toLowerCase())) return isProd ? 'unapproved' : 'nonvegan';
		else if (['unknown', 'לא ידוע'].includes(status.toLowerCase())) return isProd ? 'pending' : 'unknown';

		return null;
	}

	addProductIngredients = async (ingredients, product, company, separated) => {
		const addedIngredients = [];

		for (let ingredient of ingredients) {
			const existingIngredients = await Api.getIngredientsByName(ingredient.name);
			const existingIngredient = existingIngredients ? existingIngredients.data.find(e => e.supplier && e.supplier.id === company.id) : null;
			if (existingIngredient) {
				await Api.updateProductIngredientsByName(existingIngredient.id, product);
				addedIngredients.push({ ...existingIngredient, exists: true })

			}

			else {
				let ingredientStatus = this.getIngredientStatus(ingredient.status, !separated);

				let addedIngredient = {
					name: ingredient.name,
					supplier: company,
					comments: ingredient.comments,
					date_of_update: new Date(),
					products: [product]
				}

				if (separated) addedIngredient['status'] = ingredientStatus;

				try {
					await Api.submitIngredient(addedIngredient);
					addedIngredients.push({ ...addedIngredient, success: true })
				}
				catch (e) {
					addedIngredients.push({ ...addedIngredient, success: false })
				}
			}

		}

		return addedIngredients;
	}

	getProductStatusByIngredients(ingredientList) {

		for (let ingredient of ingredientList) {
			if (ingredient.status === 'nonvegan') return 'unapproved';
		}

		for (let ingredient of ingredientList) {
			if (!(ingredient.status === 'vegan')) return 'pending';
		}

		return 'approved'
	}

	async onMigrationClickSeparated() {
		const { selectedCompanies, excelLines } = this.state
		const products = [];
		let currentIngredients = [];
		let currentProduct = {};

		for (let company of selectedCompanies) {
			console.log('trying to migrate company', company.name);

			for (let index = 0; index < excelLines.length; index++) {
				let line = excelLines[index];
				let nextLine = excelLines[index + 1];
				const lastLineInProduct = !!(!nextLine || currentProduct.name &&
					((!nextLine['רכיבים'] && !nextLine['פירוט רכיבים']) ||
						(nextLine['שם מוצר'] || nextLine['מוצר'])));

				if (line['שם מוצר']) currentProduct.name = line['שם מוצר'];
				else if (line['שם']) currentProduct.name = line['שם'];

				if (line['ברקוד']) currentProduct.barcode = line['ברקוד'] + '';
				else if (line['בר קוד']) currentProduct.barcode = line['בר קוד'] + '';

				if (line['תאריך']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך'] - 25569) * 86400 * 1000));

				else if (line['תאריך - שימוש פנימי']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך - שימוש פנימי'] - 25569) * 86400 * 1000));
				if (line['תאריך אישור לסימון']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך אישור לסימון'] - 25569) * 86400 * 1000));

				let status = '';

				if (line['סטטוס']) status = line['סטטוס'];
				else if (line['סטטוס - שימוש פנימי']) status = line['סטטוס - שימוש פנימי'];

				let ingredient = null;
				if (line['פירוט רכיבים']) ingredient = line['פירוט רכיבים'].trim();
				else if (line['רכיבים']) ingredient = line['רכיבים'].trim();

				currentProduct.date_of_last_update = new Date();
				currentProduct.company = company;

				currentIngredients.push({ name: ingredient, status, comments: line['הערות'] })

				if (lastLineInProduct) {
					currentProduct.market_status = this.getProductStatusByIngredients(currentIngredients.map(i => ({ ...i, status: this.getIngredientStatus(i.status) })))
					// ADD Product
					try {
						let product = await Api.submitProduct(currentProduct);
						if (product) {
							const addedIngredients = await this.addProductIngredients(currentIngredients, product, company, true)
							products.push({ ...product, ingredients: addedIngredients, success: true });
							this.setState({ products })
						} else {
							products.push({ ...currentProduct, success: false });
							this.setState({ products })
						}


					} catch (e) {
						products.push({ ...currentProduct, success: false });
						this.setState({ products })

					}
					currentIngredients = [];
					currentProduct = {};
				}

			}
		}


	}

	async onMigrationClick() {
		const { selectedCompanies, excelLines } = this.state
		const products = [];
		let currentIngredients = [];
		let currentProduct;

		for (let company of selectedCompanies) {

			console.log('trying to migrate ', company.name);

			for (let index in excelLines) {
				let line = excelLines[index];
				currentProduct = {};

				if (line['ברקוד']) currentProduct.barcode = line['ברקוד'] + '';
				else if (line['בר קוד']) currentProduct.barcode = line['בר קוד'] + '';
				else if (line['מק\"ט']) currentProduct.barcode = line['מק\"ט'];


				if (line['שם מוצר']) currentProduct.name = line['שם מוצר'];
				else if (line['שם']) currentProduct.name = line['שם'];
				else if (line['מוצר']) currentProduct.name = line['מוצר'];


				if (line['הערות']) currentProduct.comments = line['הערות'];

				currentProduct.date_of_last_update = new Date();
				if (line['תאריך']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך'] - 25569) * 86400 * 1000))
				else if (line['תאריך - שימוש פנימי']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך - שימוש פנימי'] - 25569) * 86400 * 1000));
				else if (line['תאריך אישור לסימון']) currentProduct.date_of_approval = new Date(Math.round((line['תאריך אישור לסימון'] - 25569) * 86400 * 1000));

				console.log('currentProduct.date_of_approval', currentProduct.date_of_approval)
				currentProduct.company = company;

				let cleanIngredients = [];
				if (line['פירוט רכיבים']) cleanIngredients = line['פירוט רכיבים'].replace('רכיבים:', '').split(',');
				else if (line['רכיבים']) cleanIngredients = line['רכיבים'].replace('רכיבים:', '').split(',');

				const statusText = line['סטטוס'] ? line['סטטוס'] : line['סטטוס - שימוש פנימי'] ? line['סטטוס - שימוש פנימי'] : null;

				currentProduct.market_status = this.getIngredientStatus(statusText, true);

				for (let ingredient of cleanIngredients) {
					currentIngredients.push({ name: ingredient.trim(), comments: line['הערות'] })
				}

				try {
					let product = await Api.submitProduct(currentProduct);
					console.log('added this prodct: ', product.name);
					const addedIngredients = await this.addProductIngredients(currentIngredients, product, company, false)
					products.push({ ...product, ingredients: addedIngredients, success: true });
					this.setState({ products })
					currentIngredients = []

				} catch (e) {
					console.log('we have an error: ' + JSON.stringify(e))

					products.push({ ...currentProduct, success: false })
					this.setState({ products })
				}
			}

		};

		console.log('finished adding products: ' + products.length)
		this.setState({ products })

	}

	async createReport() {
		const { selectedCompanies } = this.state;
		const { labellingActions } = this.props;

		this.setState({ inProgress: true })
		console.log('about to create report!')
		for (let company of selectedCompanies) {
			await labellingActions.createReport(company.id)
			this.tryDownloadingReport();
		}

		this.setState({ inProgress: false })

	}

	makeCSV(content) {
		let csv = '';
		content.forEach(value => {
			value.forEach((item, i) => {
				let innerValue = item === null ? '' : item.toString();
				let result = innerValue.replace(/"/g, '""');
				if (result.search(/("|,|\n)/g) >= 0) {
					result = '"' + result + '"'
				}
				if (i > 0) { csv += ',' }
				csv += result;
			})
			csv += '\n';
		})
		return csv
	}


	async tryDownloadingReport() {
		console.log('this is our report: ' + JSON.stringify(this.props.labelling.report))

		let contents = [];
		contents.push(["ברקוד", "שם המוצר", "רכיבים", "סטטוס"]);
		if (this.props.labelling.report) {
			this.props.labelling.report.products.forEach(product => {
				contents.push([product.barcode ?? '', product.name ?? '', '', product.status ?? ''])
				product.ingredients.forEach(i => {
					contents.push(['', '', i.name ?? '', i.status ?? ''])
				})
			});
			const output = this.makeCSV(contents);

			const blob = new Blob([output]);
			const fileDownloadUrl = URL.createObjectURL(blob);
			this.setState({ fileDownloadUrl: fileDownloadUrl },
				() => {
					this.dofileDownload.click();
					URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
					this.setState({ fileDownloadUrl: "" })
				})
		}

	}

	async readExcel(file) {
		console.log('starting to read file')
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(file);
			fileReader.onload = (e) => {
				const bufferArray = e.target.result;
				const wb = XLSX.read(bufferArray, { type: 'buffer' });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const data = XLSX.utils.sheet_to_json(ws);
				resolve(data)
			};

			fileReader.onerror = (error) => {
				reject(error);
			}
		});

		const excelLines = await promise.then(lines => {
			return lines;
		})

		this.setState({ excelLines })

	}

	async createGs1Report() {
		const { chosen1GsCompanyId } = this.state;
		console.log('attempting to report',);

		this.setState({ inProgress: true })

		const gs1Report = await Api.getGs1ReportForCompany(chosen1GsCompanyId);
		if (gs1Report && gs1Report.productLines) {
			this.setState({ gs1Products: gs1Report.productLines, inProgress: false });
			const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

			const ws = XLSX.utils.json_to_sheet(gs1Report.productLines);
			const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
			const data = new Blob([excelBuffer], { type: fileType });
	
			saveAs(data, chosen1GsCompanyId + Date.now() + '-report.xlsx');
			console.log('after save?')

		}
	}

	render() {

		const { labelling, login } = this.props;
		const { companies } = labelling;
		const { inProgress, result, selectedCompanies, excelLines, products, ingredientsSeparated, chosen1GsCompanyId, gs1Products, columnDefs } = this.state;

		return (
			<div>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							{!companies && <h5 className="card-title text-secondary"><Text>migration.loading</Text></h5>}
							{companies &&
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h5 className="card-title text-secondary"><Text>labelling.numOfCompanies</Text><Text>{companies.length}</Text></h5>

									<Multiselect
										options={companies} // Options to display in the dropdown
										selectedValues={this.state.selectedCompanies} // Preselected value to persist in dropdown
										onSelect={this.onSelectCompany.bind(this)} // Function will trigger on select event
										onRemove={this.onRemoveCompany.bind(this)} // Function will trigger on remove event
										displayValue="name" // Property name to display in the dropdown options
										placeholder={selectedCompanies && selectedCompanies.length > 0 ? 'עוד' : 'בחרי חברה'}
										style={{ chips: { background: "green" }, searchBox: { textAlign: 'right', border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }}
									/>
									{selectedCompanies && <Text>{`נבחרו ${selectedCompanies.length} חברות`}</Text>}
									<br />
									{result && <div><Text>{result}</Text></div>}

									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<Button disabled={inProgress} success={1} onClick={ingredientsSeparated ? this.onMigrationClickSeparated.bind(this) : this.onMigrationClick.bind(this)}><Icon>yes</Icon><Text>migration.startUpload</Text></Button>
										<Button primary={1} disabled={inProgress} onClick={() => this.createReport()}><Icon>yes</Icon><Text>migration.createReport</Text></Button>
										<a className="hidden"
											download={(this.state.selectedCompanies.length > 0 ? this.state.selectedCompanies[0].name : '') + '.csv'}
											href={this.state.fileDownloadUrl}
											ref={e => this.dofileDownload = e}
										></a>

									</div>

								</div>}
						</div>
					</div>}
				<div><Text>בחרו אקסל להעלאה</Text></div>
				<form>
					<label>
						האם הרכיבים באקסל מופרדים לשורות שונות?
						<input
							name="isGoing"
							type="checkbox"
							checked={ingredientsSeparated}
							onChange={() => this.setState({ ingredientsSeparated: !ingredientsSeparated })} />
					</label>
				</form>
				<div>
					<input type="file" onChange={async (e) => {
						const file = e.target.files[0];
						this.readExcel(file);

					}} />
				</div>
				{excelLines && excelLines.length > 0 && <Text>{`Found ${excelLines.length} lines`}</Text>}
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{products && products.map(p =>

						<div>
							<h4 style={{ color: p.success ? 'green' : 'red' }}>{p.name + ' ' + (p.success ? 'התווסף בהצלחה' : 'לא התווסף בהצלחה')}</h4>
							{p.ingredients.map(i => (<h5 style={{ color: i.success ? 'green' : i.exists ? 'orange' : 'red' }}>{i.name + ' ' + (i.status ? `(${i.status}) ` : '') + (i.success ? 'התווסף בהצלחה' : i.exists ? ' כבר קיים' : 'לא התווסף בהצלחה')}</h5>))}
						</div>
					)}
				</div>
				<div style={{ marginTop: 20 }}>
					<input placeholder='Choose GS1 company' value={chosen1GsCompanyId} onChange={(e) => this.setState({ chosen1GsCompanyId: e.target.value })} />
					<Button primary={1} disabled={inProgress || !chosen1GsCompanyId} onClick={() => this.createGs1Report()}><Icon>yes</Icon><Text>migration.createReport</Text></Button>
					{
						<div className="ag-theme-alpine" style={{ height: 600, width: 1000 }}>
							<AgGridComponent
								rowData={gs1Products}
								columnDefs={columnDefs}
							/>
						</div>
					}
				</div>

			</div>
		)
	}
}


const mapStateToProps = state => {
	const { labelling, login } = state;
	return { labelling, login }
};

const mapDispatchToProps = dispatch => {
	return {
		labellingActions: bindActionCreators({ ...labellingActions }, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductLabelling);