import {Api} from "../resources/Api";

export const GET_VF_SERVICE_PROVIDERS = 'GET_VF_SERVICE_PROVIDERS';
export const GET_VF_SERVICE_PROVIDERS_ERROR = 'GET_VF_SERVICE_PROVIDERS_ERROR';
export const GET_VF_BUSINESSES = 'GET_VF_BUSINE	SSES'
export const GET_VF_BUSINESSES_ERROR = 'GET_VF_BUSINESSES_ERROR';
export const GET_VF_RECIPES = 'GET_VF_RECIPES';
export const GET_VF_RECIPES_ERROR = 'GET_VF_RECIPES_ERROR';

export const getVfBusinesses = () => {
	return async dispatch => {
		const res = await Api.getVfBusinesses();

		if (res.success) {
			return dispatch({ type: GET_VF_BUSINESSES, list: res.data.list });
		}
		else {
			return dispatch({ type: GET_VF_BUSINESSES_ERROR, error: res.error });
		}
	}
}



export const getVfRecipes = () => {
	return async (dispatch) => {
		const res = await Api.getVfRecipes();
		
		if (res.success) {
			return dispatch({ type: GET_VF_RECIPES, list: res.data.list });
		}
		else {
			return dispatch({ type: GET_VF_RECIPES_ERROR, error: res.error });
		}
	}
};

export const getVfServiceProviders = () => {
	return async (dispatch) => {
		const res = await Api.getVfServiceProviders();
		
		if (res.success) {
			return dispatch({ type: GET_VF_SERVICE_PROVIDERS, list: res.data.list });
		}
		else {
			return dispatch({ type: GET_VF_SERVICE_PROVIDERS_ERROR, error: res.error });
		}
	}
};

export const migrateServiceProvider = (id) => {
	return async dispatch => {
		console.log('adding this id: ', id);
		const res = await Api.migrateServiceProvider(id); 
		if(res.success) {
			return { success: res.data.success }
		}
		else {
			return { success: false }
		}
		
	}
}

export const migrateBusiness = (id) => {
	return async dispatch => {
		console.log('adding this id: ', id);
		const res = await Api.migrateBusiness(id); 
		if(res.success) {
			return { success: res.data.success }
		}
		else {
			return { success: false }
		}
		
	}
}

