import React, {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {Api} from '../../resources/Api';
import {
	getAddressText,
	getBranchesText,
	getBusinessNameText,
	getCategoryText,
	getDescriptionText,
	getDiscountListText,
	getEmailText,
	getFacebookUrlText,
	getInstagramUrlText,
	getMenuExistsText,
	getPhoneNumberText,
	getPicText,
	getReservationUrlText,
	getScheduleText,
	getShortDescriptionText,
	getTagText,
	getTiktokUrlText,
	getWebsiteUrlText,
} from '../../resources/EmailUtils';
import t from '../../resources/i18n';
import Button from '../utils/Button';
import './Modal.css';

const introText = t.t('restaurantFields.businessIntro');
const outroText = t.t('restaurantFields.businessOutro');
const foodPicsText = t.t('restaurantFields.foodPics');
const logoText = t.t('restaurantFields.logoText');

const BusinessModal = ({
	businessObject,
	onClose,
	isRTL,
	tags,
	businessCategories,
	businessSubcategories,
	geographicRegions,
}) => {
	const [business, setBusiness] = useState(businessObject);
	const [sendingInProgress, setSendingInProgress] = useState(false);
	const [fullText, setFullText] = useState('');
	const [email, setEmail] = useState('');
	const [replyEmail, setReplyEmail] = useState('');

	const sendEmail = async () => {
		setSendingInProgress(true);
		try {
			const response = await Api.sendEmailToBusiness(
				'העלאת לפרטיך לאפליקציית ויגן פרנדלי',
				fullText.replaceAll('\n', '<br/>').replaceAll('\t', '&emsp;'),
				email,
				replyEmail,
				isRTL
			);

			await Api.updateBusinessDaysSinceLastContact(business.id);
		} catch (e) {
			Alert('ERROR!');
		}
		setSendingInProgress(false);
	};

	useEffect(() => {
		console.log(
			'businessObject.geographic_regions, geographicRegions',
			businessObject.geographic_regions,
			geographicRegions
		);
		setBusiness(businessObject);
		let fullText =
			introText +
			'\r\n' +
			getBusinessNameText(businessObject.name) +
			'\r\n\n' +
			getShortDescriptionText(businessObject.tag_line) +
			'\r\n\n' +
			logoText +
			': \r\n' +
			getPicText(businessObject.logo) +
			'\r\n\n' +
			getAddressText(businessObject.address) +
			'\r\n\n' +
			getDescriptionText(businessObject.description) +
			'\r\n\n' +
			getPhoneNumberText(businessObject.phone_number) +
			'\r\n\n' +
			getEmailText(businessObject.email) +
			'\r\n\n' +
			getTagText(
				t.t('restaurantFields.areasOfService'),
				businessObject.geographic_regions_ids,
				geographicRegions
			) +
			'\r\n\n' +
			(businessObject.schedule ? getScheduleText(businessObject.schedule) + '\r\n\n' : '') +
			getDiscountListText(businessObject.discount_list) +
			'\r\n\n' +
			getInstagramUrlText(businessObject.instagram_url) +
			'\r\n\n' +
			getFacebookUrlText(businessObject.facebook_url) +
			'\r\n\n' +
			getWebsiteUrlText(businessObject.website_url) +
			'\r\n\n' +
			getTiktokUrlText(businessObject.tiktok_url) +
			'\r\n\n';
		if (businessObject.menu && businessObject.menu.categories) {
			for (let c of businessObject.menu.categories) {
				fullText += getCategoryText(c);
			}

			fullText += foodPicsText + '\r\n\n';

			for (let pic of businessObject.menu.food_pics) {
				fullText += getPicText(pic.url);
			}
		}
		fullText += '\r\n\n' + outroText + '\r\n\n';
		setFullText(fullText);
		setEmail(businessObject.email);
	}, [businessObject, businessObject.menu]);

	console.log();
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header>
				<Modal.Title>Business: {business.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>Send Email to: </div>
				<input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
				<div>Reply to: </div>
				<input type="text" value={replyEmail} onChange={(e) => setReplyEmail(e.target.value)} />
				<Button type="submit" success={1} disabled={sendingInProgress} onClick={sendEmail}>
					Submit
				</Button>
				<textarea
					type="text"
					cols={100}
					dir="rtl"
					rows={50}
					value={fullText}
					onChange={(e) => setFullText(e.target.value)}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default BusinessModal;
