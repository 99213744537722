import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../utils/Button';

const AgGridComponent = ({ enableRtl, rowData, columnDefs, onCellClicked = () => { } }) => {
    const gridRef = useRef();
    const autoSizeStrategy = {
        type: 'fitCellContents'
    };
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    return (
        <>
            <div style={ { marginVertical: 10 } }>
                <Button success={ 1 } onClick={ () => onBtnExport() }>Export to Excel</Button>
            </div>
            <AgGridReact

                autoSizeStrategy={ autoSizeStrategy }
                enableRtl={ enableRtl }
                rowData={ rowData }
                columnDefs={ columnDefs }
                ref={ gridRef }
                onCellClicked={ c => onCellClicked(c.data) }
            >
            </AgGridReact>
        </>

    )
}

export default AgGridComponent;

