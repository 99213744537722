import React, {Component} from 'react';
import {Column, Row} from "../utils/Row";
import Button from "../utils/Button";
import Icon from "../utils/Icon";
import {Xlate} from "../utils/Text";

export default class RestaurantMenuItemAdd extends Component {

	constructor() {
		super();
		this.state = {
			title : '',
			description: '',
			price : '',
		}
	}

	onChange = (option, value) => {
		this.setState({[option] : value});
	};

	onAddItem = () => {
		this.props.onAddItem(this.state.title, this.state.description, this.state.price);
		if (this.props.onDismiss) this.props.onDismiss();
	};


	render() {

		const item = this.state;

		return (
			<div>
				<form onSubmit={this.onAddItem}>
					<Row centered={1}>
						<input type="text" placeholder={Xlate('fields.title')} className="form-control" value={item.title} onChange={(e) => this.onChange('title', e.target.value)} />
						<input type="text" placeholder={Xlate('fields.description')} className="form-control" value={item.description} onChange={(e) => this.onChange('description', e.target.value)} />
						<input type="text" placeholder={Xlate('fields.price')} className="form-control" value={item.price} onChange={(e) => this.onChange('price', e.target.value)} />
						<Column className="col-auto font-weight-bold">
							<Button type="submit" success={1} spaced={1} onClick={this.onAddItem}><Icon>check</Icon></Button>
							<Button light={1} onClick={(this.props.onDismiss?this.props.onDismiss:null)}><Icon>times</Icon></Button>
						</Column>
					</Row>
				</form>
			</div>
		);
	}
}