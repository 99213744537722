import React from 'react';
import t from '../resources/i18n';

export const countries = [
	{
		value: 'all',
		label: 'All'
	},
	{
		value: 'IL',
		label: 'Israel'
	},
	{
		value: 'GB',
		label: 'United Kingdom'
	},
	{
		value: 'US',
		label: 'United States'
	}
]

export const languages = [
	{
		value: 'he',
		label: 'Hebrew'
	},
	{
		value: 'en',
		label: 'English'
	},
]

export const privileges = [
	{
		value: 'anonymous',
		label: 'anonymous'
	},
	{
		value: 'subscriber',
		label: 'subscriber'
	},
	{
		value: 'regular',
		label: 'regular'
	},
	{
		value: 'all',
		label: 'All app users'
	},
	{
		value: 'nonva',
		label: "Non-Vegan Active Members"
	}
]


export function DateFormat(date) {
	const r = new Date(Date.parse(date));
	let yr = r.getFullYear(), mo = r.getMonth()+1, dy = r.getDate();
	return dy+'/'+mo+'/'+yr;
}

export function DateTimeFormat(date) {

	const r = new Date(Date.parse(date));
	let yr = r.getFullYear(), mo = r.getMonth()+1, dy = r.getDate(),
		hr = r.getHours()+'', min = r.getMinutes()+'';

	hr = (hr.length > 1)?hr:'0'+hr;
	min = (min.length > 1)?min:'0'+min;

	return dy+'/'+mo+'/'+yr+' '+hr+':'+min;
}
export function Ellipsis(text, threshold = 30) {
	return text?text.substr(0, (text.length > threshold)?threshold-3:threshold) + ((text.length > threshold)?'...':''):'';
}

export function Nl2br(text) {
	return text.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})
}

export function UserStatus(status) {
	switch (status) {
		case 'anonymous': return t.t("users.anonymous");
		case 'regular': return t.t("users.regular");
		case 'subscriber': return t.t("users.subscriber");
		default: return '';
	}
}

export function userSearch(search, country, privilege, language, userList) {
	return userList ? userList
		.map(user => {
			// Collect all matches for the search term
			const matches = [];
			const searchLower = search.toLowerCase();

			if (user.email && user.email.toLowerCase().includes(searchLower)) matches.push("email");
			if (user.first_name && user.first_name.toLowerCase().includes(searchLower)) matches.push("first_name");
			if (user.last_name && user.last_name.toLowerCase().includes(searchLower)) matches.push("last_name");
			if (user.id && (user.id + '').includes(search)) matches.push("id");
			if (user.device_id && user.device_id.toLowerCase().includes(searchLower)) matches.push("device_id");
			if (user.device_type && user.device_type.toLowerCase() === searchLower) matches.push("device_type");
			if (user.version && user.version.toLowerCase() === searchLower) matches.push("version");
			if (user.installation_id && user.installation_id.toLowerCase() === searchLower) matches.push("installation_id");

			// Check full name match if first or last name didn't match
			if (matches.indexOf('first_name') < 0 && matches.indexOf('last_name') < 0) {
				if (user.first_name && user.last_name && (user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase()).includes(searchLower)) {
					matches.push("full_name");
				}
			}

			// Assign searchMatch property
			user.searchMatch = matches.length > 0 ? matches : false;
			return user;
		})
		.filter(user => {
			const hasSearchMatch = search.length === 0 || !!user.searchMatch;
			const languageMatch = (language === 'he' && user.language && user.language.startsWith('he')) || (language !== 'he' && (!user.language || !user.language.startsWith('he')));
			const privilegeMatch = 
				(privilege === 'all') ||
				(user.status === privilege) ||
				(privilege === 'anonymous' && user.anonymous) ||
				(privilege === 'nonva' && user.status !== 'subscriber');
			const countryMatch = country === 'all' || user.country === country;

			return hasSearchMatch && languageMatch && privilegeMatch && countryMatch;
		}) : [];
}
