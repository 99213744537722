import {Api} from "../resources/Api";

export const GET_SUPPORT_TICKETS_SUCCESS = 'GET_SUPPORT_TICKETS_SUCCESS';
export const GET_SUPPORT_TICKETS_ERROR = 'GET_SUPPORT_TICKETS_ERROR';
export const SET_SUPPORT_TICKET_READ_SUCCESS = 'SET_SUPPORT_TICKET_READ_SUCCESS';
export const SET_SUPPORT_TICKET_READ_ERROR = 'SET_SUPPORT_TICKET_READ_ERROR';
export const SET_SUPPORT_TICKET_UNREAD_SUCCESS = 'SET_SUPPORT_TICKET_UNREAD_SUCCESS';
export const SET_SUPPORT_TICKET_UNREAD_ERROR = 'SET_SUPPORT_TICKET_UNREAD_ERROR';
export const SET_SUPPORT_TICKET_SOLVED_SUCCESS = 'SET_SUPPORT_TICKET_SOLVED_SUCCESS';
export const SET_SUPPORT_TICKET_SOLVED_ERROR = 'SET_SUPPORT_TICKET_SOLVED_ERROR';
export const SET_SUPPORT_TICKET_UNSOLVED_SUCCESS = 'SET_SUPPORT_TICKET_UNSOLVED_SUCCESS';
export const SET_SUPPORT_TICKET_UNSOLVED_ERROR = 'SET_SUPPORT_TICKET_UNSOLVED_ERROR';
export const ASSIGN_SUPPORT_TICKET_SUCCESS = 'ASSIGN_SUPPORT_TICKET_SUCCESS';
export const ASSIGN_SUPPORT_TICKET_ERROR = 'ASSIGN_SUPPORT_TICKET_ERROR';
export const TOGGLE_SUPPORT_TICKETS_SHOW_SOLVED = 'TOGGLE_SUPPORT_TICKETS_SHOW_SOLVED';

export const getSupportTickets = () => {
	return async (dispatch) => {
		const res = await Api.supportTicketsList();

		if (res.result === "success") {
			return dispatch({ type: GET_SUPPORT_TICKETS_SUCCESS, list: res.list });
		}
		else {
			return dispatch({ type: GET_SUPPORT_TICKETS_ERROR, error: res.error });
		}
	}
};

export const toggleShowSolvedTickets = () => {
	return dispatch => dispatch({ type: TOGGLE_SUPPORT_TICKETS_SHOW_SOLVED });
}

export const setSupportTicketRead = (ticketId) => {
	return async dispatch => {
		const res = await Api.setSupportTicketRead(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_SUPPORT_TICKET_READ_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_SUPPORT_TICKET_READ_ERROR, error: res.error, ticketId });
		}
	}
};

export const setSupportTicketUnread = (ticketId) => {
	return async dispatch => {
		const res = await Api.setSupportTicketUnread(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_SUPPORT_TICKET_UNREAD_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_SUPPORT_TICKET_UNREAD_ERROR, error: res.error, ticketId });
		}
	}
};

export const setSupportTicketSolved = (ticketId) => {
	return async dispatch => {
		const res = await Api.setSupportTicketSolved(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_SUPPORT_TICKET_SOLVED_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_SUPPORT_TICKET_SOLVED_ERROR, error: res.error, ticketId });
		}
	}
};

export const setSupportTicketUnsolved = (ticketId) => {
	return async dispatch => {
		const res = await Api.setSupportTicketUnsolved(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_SUPPORT_TICKET_UNSOLVED_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_SUPPORT_TICKET_UNSOLVED_ERROR, error: res.error, ticketId });
		}
	}
};

export const assignSupportTicketToAdmin = (ticketId, adminId) => {
	return async dispatch => {
		const res = await Api.assignSupportTicketToAdmin(ticketId, adminId);

		if (res.result === "success") {
			return dispatch({ type: ASSIGN_SUPPORT_TICKET_SUCCESS, ticketId, adminId });
		}
		else {
			return dispatch({ type: ASSIGN_SUPPORT_TICKET_ERROR, error: res.error, ticketId });
		}
	}
};