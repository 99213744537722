import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { bindActionCreators } from 'redux';
import * as usersActions from '../actions/usersActions';
import NotificationModal from '../components/modal/NotificationModal';
import AgGridComponent from '../components/Restaurants/AgGridComponent';
import UserRow from '../components/users/UserRow';
import Button from '../components/utils/Button';
import Icon from '../components/utils/Icon';
import LineInput from '../components/utils/LineInput';
import { Column, Row } from '../components/utils/Row';
import Select from '../components/utils/Select';
import Text from '../components/utils/Text';
import { countries, languages, privileges, userSearch } from '../resources/Filters';
import { arrayWithoutDuplicates } from '../resources/Utils';

class Users extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			showPushNotificationModal: false,
			search: '',
			country: 'all',
			privilege: 'all',
			language: 'he',
			columnDefs: [],
		};
	}

	onChangeSearch = (search) => {
		this.setState({search});
	};

	onChangeCountry = (country) => {
		this.setState({country});
	};

	onChangeUserPrivilege = (privilege) => {
		console.log('got this privilege: ', privilege);
		this.setState({privilege});
	};

	componentDidMount() {
		// setTimeout(() => {
		// 	if (!this.props.login.loggedIn) {
		// 		this.props.history.push('/');
		// 	}
		// }, 500);

		const {getUsers, getBenefitsBrief} = this.props.usersActions;
		getUsers();

		const columnDefs = [
			{field: 'country', filter: true},
			{field: 'email', filter: true, sortable: true},
			{field: 'device_type', filter: true, sortable: true},
			{field: 'first_name', filter: true, sortable: true},
			{field: 'last_name', filter: true, sortable: true},
			{field: 'email', filter: true, sortable: true},
			{field: 'app_version', filter: true, sortable: true},
			{field: 'phone_number', filter: true, sortable: true},
			{field: 'gender', filter: true, sortable: true},
			{field: 'model_name', filter: true, sortable: true},
			{field: 'details'},
		];

		this.setState({columnDefs});

		getBenefitsBrief();
	}

	onSignOutSubscriber = (id) => {
		const {signOutSubscriber} = this.props.usersActions;
		signOutSubscriber(id);
	};

	onDeleteUser = (id) => {
		const {deleteUser} = this.props.usersActions;
		deleteUser(id);
	};

	onEmailMonthlyDiscount = (id, hash) => {
		const {emailMonthlyDiscount} = this.props.usersActions;
		console.log('hey id and hash', id, hash);
		emailMonthlyDiscount(id, hash);
	};

	async sendPushNotificationToUsers(userIdList, title, body, data) {
		const {country, privilege, language} = this.state;
		this.setState({loading: true});
		const {success} = await this.props.usersActions.sendPushNotification(
			userIdList,
			title,
			body,
			data,
			this.state.privilege === 'anonymous',
			country,
			privilege,
			language
		);
		this.setState({loading: false, error: success ? 'server error!' : null});
	}

	render() {
		let { userList,anonymousUserList, benefits } = this.props.users;
		const { search, privilege, country, showPushNotificationModal, loading, language, columnDefs } = this.state;
	
		// Filter the original userList combined with anonymousUserList
		const filteredUserList = userSearch(search, country, privilege, language, [
			...userList, // original list from import
			...anonymousUserList, // anonymous users from props
		]);
	
		const total = userList.length + anonymousUserList.length;
		const num = filteredUserList.length;
	
		// Filter notificationsUserList
		const notificationsUserList = arrayWithoutDuplicates(
			filteredUserList.filter((u) => u.has_push_token && !u.notifications_disabled),
			'push_token'
		);
	
		const renderUserList = ({ userList, benefits }) => {
			const itemSize = 120; // Adjust based on your estimated item height
	
			return (
				<List
					height={500} // Adjust to your container's height
					itemCount={userList.length}
					itemSize={itemSize}
					width="100%"
				>
					{({ index, style }) => {
						const user = userList[index];
						return (
							<div key={'user' + index} style={style} className="list-group-item">
								<UserRow
									user={user}
									onSignOutSubscriber={() => this.onSignOutSubscriber(user.id)}
									onDeleteUser={() => this.onDeleteUser(user.id)}
									benefits={benefits}
									onEmailMonthlyDiscount={(id, hash) => this.onEmailMonthlyDiscount(id, hash)}
								/>
							</div>
						);
					}}
				</List>
			);
		};
	
		return [
			<div key={'card'} className="card">
				<div className="card-body">
					<Row>
						<div className="card-title text-primary">
							<h5>
								<Text>users.users</Text>
							</h5>
							<p>
								<Text total={total} num={num}>
									users.showing
								</Text>
							</p>
						</div>
						{country !== 'all' && (
							<Column autoCol={1}>
								<Button info={1} onClick={() => this.setState({ showPushNotificationModal: true })}>
									<Icon>plus-square</Icon>
								</Button>
							</Column>
						)}
					</Row>
				</div>
				<div className="card-header">
					<LineInput key={'search-input'} noButtons={1} icon="search" onSubmit={this.onChangeSearch} />
					<Select
						selected={country}
						noButtons={1}
						key={'country-input'}
						options={countries}
						onSelect={(item) => this.setState({ country: item })}
						placeholder="Choose country"
					/>
					<Select
						selected={privilege}
						noButtons={1}
						key={'privilege-input'}
						options={privileges}
						onSelect={(item) => this.setState({ privilege: item })}
						placeholder="Choose privilege"
					/>
					<Select
						selected={language}
						noButtons={1}
						key={'language-input'}
						options={languages}
						onSelect={(item) => this.setState({ language: item })}
						placeholder="Choose language"
					/>
				</div>
				{filteredUserList && (
					<div className="list-group list-group-flush" style={{ height: 'calc(100vh - 250px)', overflowY: 'auto' }}>
						{num && renderUserList({ userList: filteredUserList, benefits })}
					</div>
				)}
			</div>,
			<div className="ag-theme-alpine" style={{ height: 600, width: 1000 }}>
				<AgGridComponent enableRtl={country === 'IL'} rowData={filteredUserList} columnDefs={columnDefs} />
			</div>,
			<NotificationModal
				userList={notificationsUserList}
				show={showPushNotificationModal}
				loading={loading}
				onClose={() => this.setState({ showPushNotificationModal: false })}
				sendPushNotification={(userIdList, title, body, data) =>
					this.sendPushNotificationToUsers(userIdList, title, body, data)
				}
			/>,
		];
	}
	
	
}

const mapStateToProps = (state) => {
	return {users: state.users, login: state.login};
};

const mapDispatchToProps = (dispatch) => {
	return {usersActions: bindActionCreators({...usersActions}, dispatch)};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
