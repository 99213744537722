import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import Text from "../components/utils/Text";
import Button from '../components/utils/Button';
import { Api } from "../resources/Api";
import AgGridComponent from "../components/Restaurants/AgGridComponent";
import RestaurantModal from "../components/modal/RestaurantModal"
import * as XLSX from 'xlsx';
import Select from 'react-select'
import { saveAs } from 'file-saver';
import axios from 'axios';
import { IMAGES_BUCKET } from '../resources/Api';

import { restaurantColumnDefs, chainsColumnDefs, getRestaurantObjectFromExcelLine,
	getPreparedEntity
 } from '../resources/Utils';



 const getErrorFromExcelLines = (lines) => {
	const errors = [];

	for(let line of lines) {
		for(let key of Object.keys(line)) {
			if(line[key].length > 255 && (key.includes('facebook') || key.includes('instagram'))) {
				errors.push({ id: line.id, name: line['Name'], field: key, type: 'name too long' });
			}

			if((line[key] + "").includes('http://') && (key.includes('logo') || key.includes('picture'))) {
				errors.push({ id: line.id, name: line['Name'], field: key, type: 'picture source is http' });
			}

		}
		if (line['email'] && !line['email'].includes('@')) errors.push({ id: line.id, name: line['Name'], field: 'email', type: 'Email is @less' });
		if (!line['google_address']) errors.push({ id: line.id, name: line['Name'], field: 'google_address', type: 'Google address is empty' });
	}

	return errors;

	
 }
class Restaurants extends Component {

	constructor() {
		super();
		this.state = {
			restaurants: null,
			chains: null,
			excelLines: [],
			excelType: 'restaurant',
			loading: false,
			fileName: '',
			tags: [],
			resultsLog: [],
			restaurantCategories: [],
			selectedRestaurant: null

		}

	}

	async readExcel(file) {
		console.log('starting to read file, ', file.name.split('.')[0])
		this.setState({ fileName: file.name.split('.')[0] });

		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(file);
			fileReader.onload = (e) => {
				const bufferArray = e.target.result;
				const wb = XLSX.read(bufferArray, { type: 'buffer' });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const data = XLSX.utils.sheet_to_json(ws);
				resolve(data)
			};

			fileReader.onerror = (error) => {
				reject(error);
			}
		});

		const excelLines = await promise.then(lines => {
			return lines;
		})

		const excelLineError = getErrorFromExcelLines(excelLines);

		excelLines.map(r => ({ name: r.Name, gluten_free: r.gluten_free, vegan: r.vegan}))

		this.setState({ excelLines, excelLineError })

	}


	async onCellClicked(selectedRestaurant) {
		const { restaurantCategories, tags } = this.state;
		this.setState({ selectedRestaurant })
		const fullRestaurant = (await Api.getRestaurantData(selectedRestaurant.id)).data;
		this.setState({ selectedRestaurant: { ...getPreparedEntity(fullRestaurant, tags, restaurantCategories),
												email: selectedRestaurant.email,
												logo: IMAGES_BUCKET + selectedRestaurant.logo
		} })
	}

	onChangeUploadType(event) {
		this.setState({ excelType: event.target.value })
	}

	
	async componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		const columnDefs = restaurantColumnDefs;

		this.setState({ columnDefs });
		const country = localStorage.getItem('country');
		const restaurantsResponse = await Api.getAllRestaurantsByCountry(country);
		console.log('our response list: ', restaurantsResponse.data.fullList.length)
		const tags = restaurantsResponse.data.tags;
		const restaurantCategories = restaurantsResponse.data.rest_categories;
		const restaurants = restaurantsResponse.data.fullList.map(r => getPreparedEntity(r, tags, restaurantCategories));
		const chains = restaurantsResponse.data.chains.map(chain => {
			return {
				...chain,
				value: chain.id,
				label: chain.name,
				tags: chain.tags.map(img => img.name),
				Images: chain.Images.map(img => img.Image.url),
				rest_categories: chain.rest_categories.map(cat => cat.name),
			}
		});

		this.setState({ restaurants, chains, tags, restaurantCategories })
	}


	async onUploadClicked() {
		const { excelLines, excelType, tags, restaurantCategories, selectedChain } = this.state;

		let executionLog = ['Starting upload...'];
		this.setState({ loading: true })
		const uploadedFileData = [];

		for (let line of excelLines) {
			console.log('reading line... ', line);
			const { restaurant, error, error_restaurant_categories, wrong_categories, error_schedule, error_location } = getRestaurantObjectFromExcelLine(line, tags, restaurantCategories, excelType);

			if (error) {
				executionLog.push(`${excelType === 'chain' ? restaurant['name'] : restaurant['restaurant_name']} failed - invalid excel: ${error}`)
			} else {
				try {
					console.log('restaurant', restaurant.restaurant_name)
					await new Promise(resolve => setTimeout(resolve, 1000));
					let submittedRestaurant = await Api.submitRestaurantFromExcelWithGooglePlaces(restaurant, excelType);
					console.log('hey line: ', line);
					
					executionLog.push(`${excelType === 'chain' ? restaurant['name'] : restaurant['restaurant_name']} added successfully with id ${submittedRestaurant.id}. strapi success? ${!!submittedRestaurant.name || !!submittedRestaurant.strapiSuccess}`)
					uploadedFileData.push({ ...line, strapi_success: !!submittedRestaurant.id, error_restaurant_categories, wrong_categories: wrong_categories.join(','), error_schedule, error_location, ...submittedRestaurant.response_details })
				} catch (e) {
					executionLog.push(`${excelType === 'chain' ? restaurant['name'] : restaurant['restaurant_name']} failed ${JSON.stringify(e)}`);
					uploadedFileData.push({ ...line, strapi_success: false })

					console.log('error with ', line.name, JSON.stringify(e))
				}
			}
			this.setState({ loading: false })
			this.setState({ resultsLog: executionLog });
		}

		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

		const ws = XLSX.utils.json_to_sheet(uploadedFileData);
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });

		this.setState({ reportData: data })
	}

	onDownloadReportClick() {
		const { reportData, fileName } = this.state;
		saveAs(reportData, fileName + '-' + Date.now() + '-report.xlsx');
	}

	render() {

		const { login } = this.props;
		const { restaurants, chains, columnDefs, selectedChain, excelType, excelLines, 
			resultsLog, reportData, loading, selectedRestaurant,
			tags, restaurantCategories, excelLineError } = this.state;

		const country = localStorage.getItem('country');

		const uploadText = excelLines.length === 0 ? 'Upload excel!' :
			excelType === 'restaurant' ? `Upload ${excelLines.length} restaurants` :
				excelType === 'chain' ? `Upload ${excelLines.length} chains` :
					excelType === 'restaurantOfChain' && selectedChain ? `Upload ${excelLines.length} restaurants of chain ${selectedChain.name}` :
						`Choose chain!`


		return (
			[<div style={{ height: '100%', width: ' 100%' }}>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-secondary"><Text>sections.restaurants</Text></h5>
							</div>
						</div>
					</div>}
				{login.loggedIn &&
					<div>
						<div style={{ marginTop: 20, marginBottom: 20 }}>
							<label style={{ fontWeight: 'bold' }}><Text bold>Choose Excel Type</Text></label>
							<div onChange={this.onChangeUploadType.bind(this)}>
								<input type="radio" value="restaurant" name="excelType" /> Restaurant
								<input type="radio" value="chain" name="excelType" /> Chain
								<input type="radio" value="restaurantOfChain" name="excelType" /> Restaurant of Chain
							</div>
							{excelType === 'restaurantOfChain' && <Select options={chains} onChange={(item) => this.setState({ selectedChain: item })} placeholder="Select an option" />}
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{resultsLog.length > 0 && <h1>Results Log</h1>}
								{resultsLog.map((r, index) => (<h2 style={{ color: r.includes('failed') ? 'red' : 'green' }} key={index}>{r}</h2>))}
							</div>
							<form>
								<input type="file" onChange={async (e) => {
									const file = e.target.files[0];
									this.readExcel(file);
								}} />
							</form>


							<div style={{ marginTop: 10 }}>
								<Button disabled={loading} success={1} onClick={() => this.onUploadClicked()}>{uploadText}</Button>
								{
									excelLineError && excelLineError.map(e => <h2 style={{ color: 'red'}}>Error with {e.name} with field {e.field} ({e.type})</h2>)
								}
							</div>
							{reportData && <div style={{ marginTop: 10 }}>
								<Button success={1} onClick={() => this.onDownloadReportClick()}>{'Download report...'}</Button>
							</div>}
						</div>
					</div>

				}
				{login.loggedIn && <div className="ag-theme-alpine" style={{ height: 600, width: 1000, marginTop: 50 }}>
					<AgGridComponent
						enableRtl={country === 'IL'}
						rowData={restaurants}
						columnDefs={columnDefs}
						onCellClicked={this.onCellClicked.bind(this)}
					/>
					<br/>
					<AgGridComponent
						enableRtl={country === 'IL'}
						rowData={chains}
						columnDefs={chainsColumnDefs}
						// onCellClicked={this.onCellClicked.bind(this)}
					/>
				</div>}
			</div>,
			selectedRestaurant &&<RestaurantModal isRTL={country === "IL"} restaurantObject={selectedRestaurant} tags={tags} restaurantCategories={restaurantCategories} onClose={() => this.setState({ selectedRestaurant: null })}/>]
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, menus: state.menus, reviews: state.reviews }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
		reviewsActions: bindActionCreators({ ...reviewsActions }, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);