import t from '../resources/i18n';
import { getDisplayHours } from './Utils';

export const naText = t.t('restaurantFields.na');
export const getScheduleText = schedule => t.t('restaurantFields.schedule') + ": " + (schedule || naText);
export const getDiscountListText = discount_list => t.t('restaurantFields.discounts') + ": " + (discount_list || naText)
export const getAddressText = address => "- " + t.t('restaurantFields.address') + ": " + (address || naText)
export const getDescriptionText = description => "- " + t.t('restaurantFields.description') + ": " + (description || naText)
export const getShortDescriptionText = description => "- " + t.t('restaurantFields.shortDescription') + ": " + (description || naText)

export const getTagsText = tags => "- " + t.t("restaurantFields.tags") + ": " + (tags || naText)
export const getPhoneNumberText = phone => "- " + t.t("restaurantFields.phone") + ": " + (phone || naText);
export const getEmailText = email => "- " + t.t("restaurantFields.email") + ": " + (email || naText);

export const getInstagramUrlText = insta => "- " + t.t("restaurantFields.instagram") + ": " + (insta || naText);
export const getFacebookUrlText = facebook => "- " + t.t("restaurantFields.facebook") + ": " + (facebook || naText);
export const getTiktokUrlText = tiktok => "- " + t.t("restaurantFields.tiktok") + ": " + (tiktok || naText);
export const getWebsiteUrlText = website => "- " + t.t("restaurantFields.website") + ": " + (website || naText);
export const getReservationUrlText = reservation => "- " + t.t("restaurantFields.reservation") + ": " + (reservation || naText);
export const getBusinessNameText = name => "- " + t.t("restaurantFields.businessName") + ": " + (name || naText);
export const getMenuExistsText = (menuText, menu) => '<u>' + menuText + ":</u> " + (!!menu ? '' : naText);
export const getPicText = pic => `<img style="height: 25; width: 25;" src="${pic}"/>`


export const getCategoryText = category => {
  let returnString = "<b>" + category.name + '</b>\r\n\n';

  const dishStrings = []
  for (let dish of category.dishes) {
    dishStrings.push("\t" + dish.name + `${!!dish.description ? `: ${dish.description}` : ''}` + ` (${dish.prices.map(p => p.price).join(",")})\r\n\n`);
  }

  returnString += dishStrings.join('');
  return returnString + "\r\n";
}

export const getTagText = (text, restCats, allCats) => {
  let returnString = `- ${text}: `;
  if (!restCats) return returnString + naText;

  const displayCats = [];
  for (let cat of allCats) {

    if (restCats.includes(cat.id)) displayCats.push(`<b>${cat.name}</b>`);
    else {
      displayCats.push(cat.name);
    }
  }

  returnString += displayCats.join(', ');

  return returnString;
}

export const getBranchesText = (branches) => {
  let returnString = "- <b>" + t.t('restaurantFields.branches') + "</b>: " + "\r\n"

  for (let branch of branches) {
    let branchString = '\t' + branch.name + "\r\n" +
      '\t' + branch.address + "\r\n" +
      (branch.schedule && branch.schedule.daily_opening ? getDisplayHours(branch.schedule.daily_opening) + "\r\n" : '') +
    (branch.phone_number ? '\t' + branch.phone_number + "\r\n": '');
    returnString += branchString + '\r\n';

  }
  return returnString;
}