import {Api} from "../resources/Api";

export const GET_CONTACT_TICKETS_SUCCESS = 'GET_CONTACT_TICKETS_SUCCESS';
export const GET_CONTACT_TICKETS_ERROR = 'GET_CONTACT_TICKETS_ERROR';
export const SET_CONTACT_TICKET_READ_SUCCESS = 'SET_CONTACT_TICKET_READ_SUCCESS';
export const SET_CONTACT_TICKET_READ_ERROR = 'SET_CONTACT_TICKET_READ_ERROR';
export const SET_CONTACT_TICKET_UNREAD_SUCCESS = 'SET_CONTACT_TICKET_UNREAD_SUCCESS';
export const SET_CONTACT_TICKET_UNREAD_ERROR = 'SET_CONTACT_TICKET_UNREAD_ERROR';
export const SET_CONTACT_TICKET_SOLVED_SUCCESS = 'SET_CONTACT_TICKET_SOLVED_SUCCESS';
export const SET_CONTACT_TICKET_SOLVED_ERROR = 'SET_CONTACT_TICKET_SOLVED_ERROR';
export const SET_CONTACT_TICKET_UNSOLVED_SUCCESS = 'SET_CONTACT_TICKET_UNSOLVED_SUCCESS';
export const SET_CONTACT_TICKET_UNSOLVED_ERROR = 'SET_CONTACT_TICKET_UNSOLVED_ERROR';
export const ASSIGN_CONTACT_TICKET_SUCCESS = 'ASSIGN_CONTACT_TICKET_SUCCESS';
export const ASSIGN_CONTACT_TICKET_ERROR = 'ASSIGN_CONTACT_TICKET_ERROR';
export const TOGGLE_CONTACT_TICKETS_SHOW_SOLVED = 'TOGGLE_CONTACT_TICKETS_SHOW_SOLVED';

export const getContactTickets = () => {
	return async (dispatch) => {
		const res = await Api.contactTicketsList();
		if (res.result === "success") {
			return dispatch({ type: GET_CONTACT_TICKETS_SUCCESS, list: res.list.data });
		}
		else {
			return dispatch({ type: GET_CONTACT_TICKETS_ERROR, error: res.error });
		}
	}
};

export const toggleShowSolvedTickets = () => {
	return dispatch => dispatch({ type: TOGGLE_CONTACT_TICKETS_SHOW_SOLVED });
}

export const setContactTicketRead = (ticketId) => {
	return async dispatch => {
		const res = await Api.setContactTicketRead(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_CONTACT_TICKET_READ_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_CONTACT_TICKET_READ_ERROR, error: res.error, ticketId });
		}
	}
};

export const setContactTicketUnread = (ticketId) => {
	return async dispatch => {
		const res = await Api.setContactTicketUnread(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_CONTACT_TICKET_UNREAD_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_CONTACT_TICKET_UNREAD_ERROR, error: res.error, ticketId });
		}
	}
};

export const setContactTicketSolved = (ticketId) => {
	return async dispatch => {
		const res = await Api.setContactTicketSolved(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_CONTACT_TICKET_SOLVED_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_CONTACT_TICKET_SOLVED_ERROR, error: res.error, ticketId });
		}
	}
};

export const setContactTicketUnsolved = (ticketId) => {
	return async dispatch => {
		const res = await Api.setContactTicketUnsolved(ticketId);

		if (res.result === "success") {
			return dispatch({ type: SET_CONTACT_TICKET_UNSOLVED_SUCCESS, ticketId });
		}
		else {
			return dispatch({ type: SET_CONTACT_TICKET_UNSOLVED_ERROR, error: res.error, ticketId });
		}
	}
};


export const assignContactTicketToAdmin = (ticketId, adminId) => {
	return async dispatch => {
		const res = await Api.assignContactTicketToAdmin(ticketId, adminId);

		if (res.result === "success") {
			return dispatch({ type: ASSIGN_CONTACT_TICKET_SUCCESS, ticketId, adminId });
		}
		else {
			return dispatch({ type: ASSIGN_CONTACT_TICKET_ERROR, error: res.error, ticketId });
		}
	}
};