import React from 'react';

export default class UsersBar extends React.Component {

	render() {

		
		return (
			<div>NOT USED</div>
			)
	}
}