import {Api} from "../resources/Api";

export const LOADING = 'LOADING_LOGIN';
export const LOGIN_LOGIN = 'LOGIN_LOGIN';
export const LOGIN_LOGIN_SUCCESS = 'LOGIN_LOGIN_SUCCESS';
export const LOGIN_LOGIN_ERROR = 'LOGIN_LOGIN_ERROR';
export const LOGIN_RESTORE_HASH = 'LOGIN_RESTORE_HASH';
export const LOGIN_RESTORE_HASH_SUCCESS = 'LOGIN_RESTORE_HASH_SUCCESS';
export const LOGIN_RESTORE_HASH_ERROR = 'LOGIN_RESTORE_HASH_ERROR';
export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';

export const login = (username, password) => {
	return async (dispatch) => {
		dispatch({
			type: LOGIN_LOGIN
		});


		const res = await Api.login(username, password);

		if (res.result === 'success') {

			localStorage.setItem('vf-admin-hash', res.hash);
			localStorage.setItem('vf-admin-username', username);
			localStorage.setItem('vf-is-super-admin', (res.superAdmin) ?true:'');

			return dispatch({
				type: LOGIN_LOGIN_SUCCESS,
				hash: res.hash,
				superAdmin : res.superAdmin
			})
		}
		else {
			return dispatch({
				type: LOGIN_LOGIN_ERROR,
				error: res.error
			});
		}
	}
};

export const testHash = () => {
	return async (dispatch) => {

		const res = await Api.testHash();
		if (res.success) {
			return dispatch({
				type: LOGIN_RESTORE_HASH_SUCCESS,
				hash: res.hash
			})
		}
		else {
			return dispatch({
				type: LOGIN_RESTORE_HASH_ERROR,
				error: res.error
			});
		}
	}
};

export const logout = () => {
	return dispatch => {
		Api.logout();
		dispatch({
			type: LOGIN_LOGOUT
		})
	}
};