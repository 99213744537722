import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import AgGridComponent from "../components/Restaurants/AgGridComponent";
import Button from '../components/utils/Button';
import Icon from "../components/utils/Icon";
import Text from "../components/utils/Text";
import { Api } from '../resources/Api';

class Contact extends Component {

	constructor() {
		super();
		this.state = {
			contacts: [],
			columnDefs: []
		}

	}

	getPreparedUserContacts(contacts) {
		return contacts.map(c => ({
			...c,
			subject: decodeURI(c.subject),
			details: decodeURI(c.details),
			full_name: decodeURI(c.full_name),
			updatedAt: c.updatedAt.split('T')[0],
			createdAt: c.createdAt.split('T')[0]

		})).sort(function (a, b) {

			return new Date(b.createdAt) - new Date(a.createdAt);
		});
	}

	async componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);


		const columnDefs = [
			{
				field: "subject", filter: true, sortable: true, resizable: true, wrapText: true,
				autoHeight: true, flex: 4
			},
			{ field: "full_name", filter: true, sortable: true, resizable: true, flex: 2 },

			{ field: "email", filter: true, sortable: true, resizable: true, flex: 3 },
			{
				field: "details", resizable: true, wrapText: true,
				autoHeight: true, flex: 4
			},
			{ field: "status", resizable: true, flex: 1 },
			{ field: "country", resizable: true, flex: 1 },
			{ field: "createdAt", filter: true, sortable: true, resizable: true, flex: 2 },
			{ field: "updatedAt", filter: true, sortable: true, resizable: true, flex: 2 },

		];


		this.setState({ columnDefs });
		const country = localStorage.getItem('country');
		const userContactsResponse = await Api.getAllUserContactsByCountry(country ?? 'IL');
		const contacts = this.getPreparedUserContacts(userContactsResponse.data.contacts);
		this.setState({ contacts })
	}

	render() {
		const { login } = this.props;
		const { columnDefs, contacts } = this.state;
		const country = localStorage.getItem('country');

		return (
			<div style={ { height: '100%', width: ' 100%' } }>
				{ login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-secondary"><Text>sections.contact</Text></h5>
							</div>
						</div>

					</div> }
				{ contacts.length > 0 && (

					<div className="ag-theme-alpine" style={ { height: 600, width: '100%' } }>
						<AgGridComponent
							enableRtl={ country === 'IL' }
							rowData={ contacts }
							columnDefs={ columnDefs }
						/>
					</div>
				) }
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, menus: state.menus, reviews: state.reviews }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({ ...loginActions }, dispatch),
		reviewsActions: bindActionCreators({ ...reviewsActions }, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Contact);