import React, { Component } from 'react';
import { connect } from 'react-redux'
import Text from "../components/utils/Text";
import { Api } from "../resources/Api";
import AgGridComponent from "../components/Restaurants/AgGridComponent";
import RestaurantModal from "../components/modal/RestaurantModal"
import { IMAGES_BUCKET } from '../resources/Api';

import { businessColumnDefs, getPreparedEntity } from '../resources/Utils';
import BusinessModal from '../components/modal/BusinessModal';

class Businesses extends Component {
	constructor() {
		super();
		this.state = {
			businesses: null,
			loading: false,
			tags: [],
			businessCategories: [],
			businessSubcategories: [],
			geographic_regions: [],
			selectedBusiness: null
		}
	}
	
	async onCellClicked(selectedBusiness) {
		const { businessCategories, businessSubcategories, tags } = this.state;
		const fullBusiness = (await Api.getBusinessData(selectedBusiness.id)).data;
		this.setState({ selectedBusiness: { ...getPreparedEntity(fullBusiness, tags, businessCategories, businessSubcategories ),
												email: selectedBusiness.email,
												logo: IMAGES_BUCKET + selectedBusiness.logo
		} })
	}
	
	async componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		const columnDefs = businessColumnDefs;

		this.setState({ columnDefs });
		const country = localStorage.getItem('country');
		const businessesResponse = await Api.getAllBusinessesByCountry(country);
		const { tags, business_categories, business_subcategories, geographic_regions } = businessesResponse.data;
		console.log('businessesResponse.data.fullList', businessesResponse.data.fullList.map(r => r.schedule));
		const businesses = businessesResponse.data.fullList.map(r => getPreparedEntity(r, tags, business_categories, business_subcategories));

		this.setState({ businesses, tags, business_categories, business_subcategories, geographic_regions: geographic_regions.filter(g => g.country === country) })
	}

	render() {

		const { login } = this.props;
		const { businesses, columnDefs, selectedBusiness,
			tags, business_categories, business_subcategories, geographic_regions } = this.state;

		const country = localStorage.getItem('country');


		return (
			[<div style={{ height: '100%', width: ' 100%' }}>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title text-secondary"><Text>sections.businesses</Text></h5>
							</div>
						</div>
					</div>}
				{login.loggedIn && <div className="ag-theme-alpine" style={{ height: 600, width: 1000, marginTop: 50 }}>
					<AgGridComponent
						enableRtl={country === 'IL'}
						rowData={businesses}
						columnDefs={columnDefs}
						onCellClicked={this.onCellClicked.bind(this)}
					/>
				</div>}
			</div>,
			selectedBusiness && <BusinessModal isRTL={country === "IL"} businessObject={selectedBusiness} tags={tags} businessCategories={business_categories} businessSubcategories={business_subcategories} geographicRegions={geographic_regions} onClose={() => this.setState({ selectedBusiness: null })}/>]
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, menus: state.menus, reviews: state.reviews }
};

const mapDispatchToProps = dispatch => {
	return {
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Businesses);