import i18n from 'i18next';
import { he, en } from './locales';
const options = {
	interpolation: {
		escapeValue: false, // not needed for react!!
	},
	debug: true,
	lng: 'he',
	resources: {
		he: {
			common: he,
		},
		en: {
			common: en,
		},
	},

	fallbackLng: 'en',
	ns: ['common'],
	defaultNS: 'common',
	react: {
		wait: false,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	},
};

i18n
	.init(options);

export default i18n;