import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as migrationActions from '../actions/migrationActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";

import Multiselect from 'multiselect-react-dropdown';
import Button from '../components/utils/Button';


class WebsiteMigration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedVfServiceProviders: [],
			selectedVfBusinesses: [],
			result: null
		}
	}

	componentDidMount = async () => {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

	}

	onSelectServiceProvider(vfServiceProvider) {
		const { selectedVfServiceProviders } = this.state
		this.setState({ selectedVfServiceProviders: vfServiceProvider, ...selectedVfServiceProviders, result: ''})
	}

	onRemoveServiceProvider(vfServiceProvider) {
		const { selectedVfServiceProviders } = this.state

		this.setState({ selectedVfServiceProviders: vfServiceProvider, ...selectedVfServiceProviders.filter(c => c.id !== vfServiceProvider.id), result: '' })
	}


	onSelectBusiness(business) {
		const { selectedVfBusinesses } = this.state
		this.setState({ selectedVfBusinesses: business, ...selectedVfBusinesses, result: ''})
	}

	onRemoveBusiness(business) {
		const { selectedVfBusinesses } = this.state

		this.setState({ selectedVfBusinesses: business, ...selectedVfBusinesses.filter(c => c.id !== selectedVfBusinesses.id), result: '' })
	}

	async onServiceProviderMigrationClick() {
		const { selectedVfServiceProviders } = this.state
		const { migrationActions } = this.props;

		for(let selectedVfServiceProvider of selectedVfServiceProviders) {
			console.log('trying to migrate ', JSON.stringify(selectedVfServiceProvider) );
			const { success } = await migrationActions.migrateServiceProvider(selectedVfServiceProvider.id);
			console.log('success? ', success)
			if(!success) this.setState({ result: 'נותן השירות לא נמצא' })
			else{ this.setState({ result: 'נותן השירות התווסף בצורה תקינה!'})}
			
		}
	}

	async onBusinessMigrationClick() {
		const { selectedVfBusinesses } = this.state
		const { migrationActions } = this.props;

		for(let selectedVfBusiness of selectedVfBusinesses) {
			console.log('trying to migrate ', JSON.stringify(selectedVfBusiness) );
			const { success } = await migrationActions.migrateBusiness(selectedVfBusiness.id);
			console.log('success? ', success)
			if(!success) this.setState({ result: 'בעל העסק לא נמצא' })
			else { this.setState({ result: 'בעל העסק התווסף בצורה תקינה!'})}
			
		}
	}

	render() {

		const { migration, login } = this.props;
		const { vfServiceProviders, vfBusinesses } = migration;
		const { inProgress, result, selectedVfServiceProviders, selectedVfBusinesses } = this.state;
		
		return (
			<div>
				{login.loggedIn &&
					<div className="card-deck mt-3">
						<div className="card">
							{!vfServiceProviders && <h5 className="card-title text-secondary"><Text>migration.loading</Text></h5>}
							{vfServiceProviders &&
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h5 className="card-title text-secondary"><Text>migration.numOfVfServiceProviders</Text><Text>{vfServiceProviders.length}</Text></h5>

									<Multiselect
										options={vfServiceProviders} // Options to display in the dropdown
										selectedValues={this.state.selectedVfServiceProviders} // Preselected value to persist in dropdown
										onSelect={this.onSelectServiceProvider.bind(this)} // Function will trigger on select event
										onRemove={this.onRemoveServiceProvider.bind(this)} // Function will trigger on remove event
										displayValue="name_heb" // Property name to display in the dropdown options
										placeholder={selectedVfServiceProviders && selectedVfServiceProviders.length > 0 ? 'עוד' : 'בחרי חברה'}
										style={{ chips: { background: "green" }, searchBox: { textAlign: 'right', border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }}
									/>
									{selectedVfServiceProviders && <Text>{`נבחרו ${selectedVfServiceProviders.length} חברות`}</Text>}
									<br />
									{result && <div><Text>{result}</Text></div>}

									<Button disabled={inProgress} success={1} onClick={this.onServiceProviderMigrationClick.bind(this)}><Icon>yes</Icon><Text>migration.startUpload</Text></Button>
								</div>}
						</div>
						<div className="card">
						{!vfBusinesses && <h5 className="card-title text-secondary"><Text>migration.loading</Text></h5>}
						{vfBusinesses &&
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h5 className="card-title text-secondary"><Text>migration.numOfVfBusinesses</Text><Text>{vfBusinesses.length}</Text></h5>

									<Multiselect
										options={vfBusinesses} // Options to display in the dropdown
										selectedValues={this.state.selectedVfBusinesses} // Preselected value to persist in dropdown
										onSelect={this.onSelectBusiness.bind(this)} // Function will trigger on select event
										onRemove={this.onRemoveBusiness.bind(this)} // Function will trigger on remove event
										displayValue="name_heb" // Property name to display in the dropdown options
										placeholder={selectedVfBusinesses && selectedVfBusinesses.length > 0 ? 'עוד' : 'בחרי חברה'}
										style={{ chips: { background: "green" }, searchBox: { textAlign: 'right', border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }}
									/>
									{selectedVfBusinesses && <Text>{`נבחרו ${selectedVfBusinesses.length} עסקים`}</Text>}
									<br />
									{result && <div><Text>{result}</Text></div>}

									<Button disabled={inProgress} success={1} onClick={this.onBusinessMigrationClick.bind(this)}><Icon>yes</Icon><Text>migration.startUpload</Text></Button>
								</div>}
						</div>


					</div>}
			</div>
		)
	}
}


const mapStateToProps = state => {
	const { migration, login } = state;
	return { migration, login }
};

const mapDispatchToProps = dispatch => {
	return {
		migrationActions: bindActionCreators({ ...migrationActions }, dispatch),
	
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(WebsiteMigration);