import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as usersActions from '../actions/usersActions';
import Text from "../components/utils/Text";
import UsersBar from "../components/graphs/UsersBar";

class UserGraphs extends Component {

	componentDidMount() {

		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);

		const {getUserCountDaily} = this.props.usersActions;
		this.reloadInterval = setInterval(getUserCountDaily, 10000);
	}

	componentWillUnmount() {
		clearTimeout(this.reloadInterval);
	}

	getDailySum(userType) {
		const { dailyCount } = this.props.users;
		let c = 0;

		for (let d of dailyCount) {

			switch (userType) {
				case 'total': case 'all':
					c += d.total *1;
					break;
				case 'regular':
					c += d.regular *1;
					break;
				case 'subscriber':
					c += d.subscribers *1;
					break;
			}
		}
		return c;
	}

	render() {
		const { dailyCount } = this.props.users;

		if (dailyCount.length <= 0) return null;

		return (
			<div className="card">
				<div className="card-body">
					<h5 className="card-title text-primary"><Text>users.dailyUsers</Text></h5>
					<div style={{display: 'flex', textAlign: 'center'}}>
						<div style={{backgroundColor: '#d70206', color: '#ffffff', flex: 1, padding: 10}}>
							<h4><Text>users.total</Text></h4>
							<h1>{this.getDailySum('all')}</h1>
							<p><Text>lastHour</Text>: <b>{dailyCount[dailyCount.length-1].total}</b></p>
						</div>
						<div style={{backgroundColor: '#f05b4f', color: '#ffffff', flex: 1, padding: 10}}>
							<h4><Text>users.regular</Text></h4>
							<h1>{this.getDailySum('regular')}</h1>
							<p><Text>lastHour</Text>: <b>{dailyCount[dailyCount.length-1].regular}</b></p>
						</div>
						<div style={{backgroundColor: '#f4c63d', flex: 1, padding: 10}}>
							<h4><Text>users.subscriber</Text></h4>
							<h1>{this.getDailySum('subscriber')}</h1>
							<p><Text>lastHour</Text>: <b>{dailyCount[dailyCount.length-1].subscribers}</b></p>
						</div>
					</div>
				</div>
				<div className="card-body">
					<UsersBar data={dailyCount} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {users: state.users, login: state.login }
};

const mapDispatchToProps = dispatch => {
	return { usersActions: bindActionCreators(usersActions, dispatch) }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGraphs);