import foodCategories from './food-categories'
import { categoryMapper } from './il-food-categories';
import tagList from './tag-list'
import menuCategories from './menu-categories';
import { Api } from "../resources/Api";
import { Children } from 'react';

class ObjectHandler {

    getFoodCategories = (restaurantTags, restaurantCategories) => {
        //const categoryFilter = id => 

        return foodCategories.filter(foodCategory => {
            let currentCategory = categoryMapper.find(c => (c.name == foodCategory.category_name))
            let tags = currentCategory ? currentCategory.tags : [];
            let categories = currentCategory ? currentCategory.categories : [];
            console.log('comparing current category: ' + currentCategory.name, JSON.stringify(tags), JSON.stringify(categories));
            for (let tag of tags) {
                if (restaurantTags.indexOf(tag + "") > -1) return true;
            }

            for (let category of categories) {
                if (restaurantCategories.indexOf(category + "") > -1) return true;
            }

            return false;
        })
    }


    getRestaurantTags = (restaurant) => {
        const restaurantTags = [];
        restaurant['is_accessible'] && restaurantTags.push('Wheelchair Accessible');
        restaurant['is_kosher'] && restaurantTags.push('Kosher');
        restaurant['active'] === 1 && restaurantTags.push('Certified');
        restaurant['is_vegan'] && restaurantTags.push('Vegan');

        restaurant['tags'].includes("32") && restaurantTags.push('Gluten Free');

        return tagList.filter(tag => restaurantTags.indexOf(tag.name + "") > - 1)
    }

    getChainObjects = async (chainList) => {
        const chainObjects = [];
        for (let chainItem of chainList) {
            let chainFull = await Api.getIlRestaurantData(chainItem.branch_id)

            const chain = { ...chainFull, ...chainItem };

            let chainObject = {};

            chainObject.name = chain.restaurant_name;
            chainObject.description = chain.descriptionShort;
            chainObject.long_description = chain.description;

            chainObject.approved = false//chain.active === 1
            chainObject.vegan = chain.is_vegan === 1
            chainObject.website_url = chain.website;
            chainObject.search_names = chain.search_names
            chainObject.facebook_url = chain.facebook
            chainObject.instagram_url = this.getInstagramUrl(chain.menu);
            console.log('our discount: ' + JSON.stringify(chain));
            
            if (chain.discount) {
                chainObject.discount = {
                    description: chain.discount.title,
                    terms: 'ההטבה מוגבלת לסועד אחד'
                }
            }

            chainObject.label_approved = chain.active === 1;

            chainObject.legacy_id = chain.restaurant_id;
            chainObject.locale = 'he-IL'
            chainObject.country = 'IL'
            chainObject.food_categories = this.getFoodCategories(chain.tags, chain.category);
            chainObject.tags = this.getRestaurantTags(chain)

            let blob = await fetch(window.location.origin + "/logos/" + chain.logo).then(r => r.blob());
            const foodPicName = chain.logo.slice((chain.logo.lastIndexOf(".") - 1 >>> 0) + 2);

            const logo = await Api.uploadPhoto(blob, foodPicName);

            chainObject.logo = logo;

            const menuItems = await Api.getMenuItemsForRestaurant(chain.restaurant_id);

            chainObject.menu = await this.getMenuObject(menuCategories, menuItems, chain.imgUrls)

            chainObjects.push(chainObject);

        }

        return chainObjects;
    }

    getDailyOpening = (openings) => {
        console.log('getDailyOpening: ' + JSON.stringify(openings));
        return [
            ...openings.map(opening => {

                let from = (opening[2].split(':')[0].length === 1 ? '0' : '') + opening[2] + ":00.000";
                let to = (opening[3].split(':')[0].length === 1 ? '0' : '') + opening[3].replace("24:", "00:") + ":00.000";

                console.log('from, to: ', from, to);
                return {
                    from,
                    to,
                    from_day: (opening[0] + 6) % 7,
                    to_day: (opening[1] + 6) % 7
                }
            })
        ]
    }

    getGeoRegion(id) {
        console.log('got this regionId: ' + id);
        switch (parseInt(id)) {
            case 1:
                return 'פריסה ארצית';
            case 2:
                return 'מרכז';
            case 3:
                return 'ירושלים והסביבה';
            case 4:
                return 'השרון';
            case 5:
                return 'צפון';
            case 6:
                return 'דרום';
            case 7:
                return 'תל אביב';
            case 8:
                return 'שפלה';
            case 9:
                return 'חיפה והסביבה';
            case 11:
                return 'יהודה ושומרון';
            default:
                return '';
        }
    }

    getInstagramUrl(menu) {

        //href=\"https://www.instagram.com/anastasiatlv/\">

        if (menu) {
            const words = menu.split(' ');

            for (let w of words) {
                console.log(w + " : " + w.includes('instagram'))
                if (w.startsWith('href') && w.includes('instagram')) {
                    return w.split('"')[1];
                }
            }
        }

        console.log('we could not find an instgram link')
        return '';

    }

    getRestaurantObject = async (restaurantItem, chainObjects, foodCategories) => {
        const restaurantChainObject = chainObjects.find(chain => chain.legacy_id == restaurantItem.restaurant_id)
        let restaurantObject = {};

        let restaurantFull = await Api.getIlRestaurantData(restaurantItem.branch_id)
        let restaurant = { ...restaurantItem, imgUrls: restaurantFull.imgUrls }

        console.log('currently, our restaurant object is: ' + JSON.stringify(restaurant));

        restaurantObject.restaurant_name = restaurant.restaurant_name;
        restaurantObject.description = restaurantChainObject && restaurantChainObject.description ? null : restaurant.descriptionShort;
        restaurantObject.long_description = restaurantChainObject && restaurantChainObject.long_description ? null : restaurant.description;//restaurantChainObject && restaurantChainObject.description ? restaurantChainObject.description : restaurant.about
        restaurantObject.approved = false//restaurant.active === 1
        restaurantObject.vegan = restaurantChainObject && restaurantChainObject.vegan ? true : restaurant.is_vegan == "1" ? true : false
        restaurantObject.website_url = restaurantChainObject && restaurantChainObject.website_url ? null : restaurant.website;
        restaurantObject.instagram_url = restaurantChainObject && restaurantChainObject.instagram_url ? null : this.getInstagramUrl(restaurant.menu);
        const lon = parseFloat(restaurant.lon);
        const lat = parseFloat(restaurant.lat);

        if (!isNaN(lon) && !isNaN(lat)) {
            restaurantObject.location = {
                lon: restaurant.lon,
                lat: restaurant.lat
            }
        }

        restaurantObject.geo_region = this.getGeoRegion(restaurant.geographic_location_id)
        restaurantObject.address = restaurant.address;
        restaurantObject.phone_number = restaurant.phone;

        restaurantObject.label_approved = restaurant.active === 1;

        restaurantObject.legacy_id = restaurant.branch_id;
        restaurantObject.search_names = restaurantChainObject && restaurantChainObject.search_names ? null : restaurant.search_names
        restaurantObject.facebook_url = restaurantChainObject && restaurantChainObject.facebook_url ? null : restaurant.facebook
        restaurantObject.locale = 'he-IL'
        restaurantObject.country = 'IL'
        restaurantObject.food_categories = this.getFoodCategories(restaurant.tags, restaurant.category);
        restaurantObject.tags = this.getRestaurantTags(restaurant)
        if (restaurantChainObject && restaurantChainObject.logo) {
            restaurantObject.logo = null;
        }
        else {
            let blob = await fetch(window.location.origin + "/logos/" + restaurant.logo).then(r => r.blob());
            const foodPicName = `1.${restaurant.logo.split('.')[1]}`
            console.log('trying to upload: ' + foodPicName);
            let logo = await Api.uploadPhoto(blob, foodPicName);
            restaurantObject.logo = logo;
        }

        const menuItems = await Api.getMenuItemsForRestaurant(restaurant.restaurant_id);
        restaurantObject.labelled = {
            menu: restaurantChainObject && restaurantChainObject.menu ? null : await this.getMenuObject(menuCategories, menuItems, restaurant.imgUrls)
        }

        restaurantChainObject && restaurantChainObject.discount ?
            restaurantObject.discount = null :
            restaurant.discount ?
                restaurantObject.discount = {
                    description: restaurant.discount.title,
                    terms: 'ההטבה מוגבלת לסועד אחד'
                } :
                restaurantObject.discount = null


        if (restaurantChainObject) {
            console.log('our chain: ' + restaurantChainObject.name);
            restaurantObject.chain = restaurantChainObject
        }

        if (restaurant.hours) {
            restaurantObject.schedule = { daily_opening: this.getDailyOpening(restaurant.hours) };
        }

        return restaurantObject

    }

    async getReviewsObjectForRestaurant(restaurantId, branchId, newRestaurantId = -1) {

        let reviewItems = await Api.getReviewsForRestaurant(restaurantId, branchId);
        /*
        reviewItems = reviewItems.sort((a, b) => {
            const aDate = new Date(a.created ? a.created.split(' ')[0] : '');
            const bDate = new Date(b.created ? b.created.split(' ')[0] : '');
            return bDate - aDate;
        }) */

        const reviewObjects = []

        for (let item of reviewItems.reverse()) {
            const description = item.description ? encodeURI(item.description) : '';

            let created = item.created ? item.created.split(' ')[0] : ''

            reviewObjects.push({
                reviewer_name: item.first_name,
                description,
                score: parseInt(item.rating),
                approved: true,
                reviewer_hash: item.user_id ? item.user_id : Math.random(10000000) + "",
                avatar: parseInt(item.avatar),
                created,
                restaurant: {
                    id: newRestaurantId
                }
            })
        }
        return reviewObjects;
    }

    getCategoryDishes(categoryId, menuItems) {
        let dishes = [];

        for (let item of menuItems) {
            if (item.section_id == categoryId) {

                const dish_prices = item.price ? item.price.replace(' ', '') : null;
                const parsed_dish_prices = dish_prices ?
                    dish_prices.includes('/') ?
                        dish_prices.split('/') :
                        dish_prices.split('-') : [];
                const prices = parsed_dish_prices.map(dishPrice => ({ price: dishPrice }));

                /*
                const dish_prices = item.price ? item.price.split('/') : [];
                const prices = dish_prices.map(dishPrice => ({ price: dishPrice }));
                */

                dishes.push({
                    name: item.title ? item.title.replace('/', '\\') : '',
                    description: item.description ? item.description.replace('/', '\\') : '',
                    vote_counter: parseInt(item.votes),
                    prices
                })

            }
        }

        console.log('these are the dishes: ' + JSON.stringify(dishes))
        return dishes;

    }

    getFoodPics = async (foodPics) => {
        let food_pics = [];
        for (let index in foodPics) {

            let fileName = foodPics[index].replace(/^.*[\\\/]/, '');

            console.log('got this filename: ' + fileName, fileName.split('_')[1], fileName.split('.')[1]);
            const foodPicName = `${index < 10 ? '0' + index : index}.${fileName.split('.')[1]}`
            let blob = await fetch(window.location.origin + "/foodPics/img_" + fileName.replace('thumb_', '')).then(r => r.blob());
            console.log('trying to fetch now: /foodPics/' + fileName.split('_')[1]);
            let uploadedFoodPic = await Api.uploadPhoto(blob, foodPicName);


            food_pics.push(uploadedFoodPic)
        }

        return food_pics;
    }

    getMenuObject = async (menuSections, menuItems, foodPics) => {
        let menu = {};

        const categories = menuSections.filter(menuSection => menuItems.map(item => item.section_id).includes(menuSection.id)).sort((a, b) => a.position - b.position);
        const menuCategories = [];
        categories.forEach(element => {
            let category = {};
            category.name = element.title;
            category.dishes = this.getCategoryDishes(element.id, menuItems);
            menuCategories.push(category)
        });

        menu.categories = menuCategories;

        const food_pics = await this.getFoodPics(foodPics);
        menu.food_pics = food_pics;

        return menu;
    }
}

export default new ObjectHandler();