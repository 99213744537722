import {
	GET_RESTAURANTS_SUCCESS,
	GET_RESTAURANTS_ERROR,
	GET_MENU_SECTIONS_SUCCESS,
	GET_MENU_SECTIONS_ERROR,
	MENU_SECTIONS_MERGE_SUCCESS,
	MENU_SECTIONS_SORT,
	MENU_SECTIONS_EDIT,
	MENU_SECTIONS_ADD,
	ADD_RESTAURANT_MENU_SECTION,
	GET_MENU_ITEMS,
	CLEAR_MENU_ITEMS,
	ADD_MENU_ITEM,
	LOADING, EDIT_MENU_ITEM, DELETE_MENU_ITEM, RELOAD_RESTAURANTS_SUCCESS, RELOAD_RESTAURANTS_ERROR,
} from '../actions/menusActions';

const defaultState = {
	restaurants : [],
	restaurantsError: null,
	reloadError: null,
	menuSections : [],
	menuSectionsError: null,
	menuItems : [],
	menuItemsCleared : false,
	currentSections : [],
	loading : false,
};

export default function MenusReducer (state = defaultState, action) {
	switch (action.type) {

		case LOADING : {
			return {...state, loading: true};
		}
		///////////////////////////////////////////////////////////////////////
		// Restaurants
		///////////////////////////////////////////////////////////////////////
		case GET_RESTAURANTS_SUCCESS: {
			return {...state, restaurants: action.list }
		}

		case GET_RESTAURANTS_ERROR: {
			return {...state, restaurantsError: action.error }
		}

		case RELOAD_RESTAURANTS_SUCCESS: {
			return {...state, loading: false }
		}

		case RELOAD_RESTAURANTS_ERROR: {
			return {...state, loading: false, reloadError: action.error }
		}
		///////////////////////////////////////////////////////////////////////
		// Menu Sections
		///////////////////////////////////////////////////////////////////////
		case GET_MENU_SECTIONS_SUCCESS: {
			return {...state, menuSections: action.list }
		}

		case GET_MENU_SECTIONS_ERROR: {
			return {...state, menuSectionsError: action.error }
		}

		case MENU_SECTIONS_MERGE_SUCCESS: {
			const oldIndex = state.menuSections.findIndex(section => section.id*1 === action.oldSection*1);
			const newIndex = state.menuSections.findIndex(section => section.id*1 === action.newSection*1);

			let menuSections = state.menuSections;
			menuSections[newIndex].num = menuSections[newIndex].num*1 + menuSections[oldIndex].num*1;
			menuSections = [...state.menuSections.slice(0,oldIndex), ...state.menuSections.slice(oldIndex+1)];

			return {...state, menuSections : menuSections }
		}

		case MENU_SECTIONS_SORT: {
			let menuSections = state.menuSections;
			if (action.sections) {
				for (let section of action.sections) {
					const index = menuSections.findIndex(s => s.id*1 === section.id*1);
					menuSections[index].position = section.position;
				}
			}

			return { ...state, menuSections };
		}

		case ADD_RESTAURANT_MENU_SECTION : {
			const index = state.menuSections.findIndex(s => s.id*1 === action.sectionId*1);
			return {...state, currentSections : [...state.currentSections, state.menuSections[index].id]};
		}

		case MENU_SECTIONS_EDIT : {
			const index = state.menuSections.findIndex(s => s.id*1 === action.id*1);
			const menuSections = [...state.menuSections.slice(0,index), {...state.menuSections[index], title: action.title}, ...state.menuSections.slice(index+1)];

			return {...state, menuSections }
		}

		case MENU_SECTIONS_ADD : {
			const menuSections = [...state.menuSections, { id: action.id, title: action.title, position: action.position, num: 0 }];

			return {...state, menuSections }
		}

		///////////////////////////////////////////////////////////////////////
		// Menu Items
		///////////////////////////////////////////////////////////////////////

		case GET_MENU_ITEMS: {
			return {...state, menuItems : action.menuItems, currentSections: action.sections, loading: false, menuItemsCleared : false };
		}

		case CLEAR_MENU_ITEMS: {
			return {...state, menuItems : defaultState.menuItems, menuItemsCleared : true }
		}

		case ADD_MENU_ITEM: {
			return {...state, loading: false, menuItems : [...state.menuItems, {id : action.id, restaurant_id : action.restaurantId, section_id : action.sectionId, title: action.title, description: action.description, price: action.price}]}
		}

		case EDIT_MENU_ITEM: {
			const index = state.menuItems.findIndex(s => s.id*1 === action.itemId*1);
			const menuItems = [...state.menuItems.slice(0,index), {...state.menuItems[index], title: action.title, description: action.description, price: action.price}, ...state.menuItems.slice(index+1)];
			return {...state, loading: false, menuItems }
		}

		case DELETE_MENU_ITEM: {
			const index = state.menuItems.findIndex(s => s.id*1 === action.itemId*1);
			return {...state, loading: false, menuItems: [...state.menuItems.slice(0,index), ...state.menuItems.slice(index+1)] }
		}

		default:
			return state;

	}

};
