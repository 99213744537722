import {Api} from "../resources/Api";

export const LOADING = 'LOADING_ADMINS';
export const ADMINS_ERROR = 'ADMINS_ERROR';
export const ADMINS_GET_LIST_SUCCESS = 'ADMINS_GET_LIST_SUCCESS';
export const ADMINS_CREATE_SUCCESS = 'ADMINS_CREATE_SUCCESS';
export const ADMINS_EDIT_SUCCESS = 'ADMINS_EDIT_SUCCESS';
export const ADMINS_DELETE_SUCCESS = 'ADMINS_DELETE_SUCCESS';
export const GENERATE_HASH_RESET = 'GENERATE_HASH_RESET';
export const GENERATE_HASH_SUCCESS = 'GENERATE_HASH_SUCCESS';
export const GENERATE_HASH_ERROR = 'GENERATE_HASH_ERROR';

export const getAdminUsers = () => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});
		const res = await Api.adminsGetList();
		if (res.result === "success") {
			return dispatch({ type: ADMINS_GET_LIST_SUCCESS, list: res.list });
		}
		else return dispatch({ type: ADMINS_ERROR, error: res.error });
	}
};

export const addAdmin = (username, password, full_name) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.adminsCreateAdmin(username, password, full_name);

		if (res.result === "success") {
			return dispatch({ type: ADMINS_CREATE_SUCCESS, id: res.id, username, full_name });
		}
		else return dispatch({ type: ADMINS_ERROR, error: res.error });
	}
};

export const editAdmin = (adminId, username, password) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.adminsEditAdmin(adminId, username, password);
		if (res.result === "success") {
			return dispatch({ type: ADMINS_EDIT_SUCCESS, username, adminId });
		}
		else return dispatch({ type: ADMINS_ERROR, error: res.error });
	}
};

export const deleteAdmin = (adminId) => {
	return async (dispatch) => {
		dispatch({
			type: LOADING
		});

		const res = await Api.adminsDeleteAdmin(adminId);
		if (res.result === "success") {
			return dispatch({ type: ADMINS_DELETE_SUCCESS, adminId });
		}
		else return dispatch({ type: ADMINS_ERROR, error: res.error });
	}
};

export const generateHash = (string) => {
	return async (dispatch) => {

		dispatch({
			type: GENERATE_HASH_RESET
		});

		dispatch({
			type: LOADING
		});


		const res = await Api.generateHash(string);
		if (res.result === 'success') {
			return dispatch({
				type: GENERATE_HASH_SUCCESS,
				hash: res.hash
			})
		}
		else {
			return dispatch({
				type: GENERATE_HASH_ERROR,
				error: res.error
			});
		}
	}
};
