import {
	LOADING,
	USERS_GET_COUNT_SUCCESS,
	USERS_GET_COUNT_ERROR,
	USERS_GET_LIST_SUCCESS,
	USERS_GET_LIST_ERROR,
	USERS_GET_BENEFITS_SUCCESS,
	USERS_GET_BENEFITS_ERROR,
	USERS_SIGN_OUT_SUBSCRIBER_SUCCESS,
	USERS_DELETE_SUCCESS, USERS_GET_COUNT_DAILY_SUCCESS
} from '../actions/usersActions';

const defaultState = {
	count : {anonymous : 0, regular : 0, subscriber : 0},
	dailyCount : [],
	userList : [],
	anonymousUserList: [],
	benefits: [],
	countError : null,
	listError : null,
	benefitsError: null,
	loading: false,
};

export default function UsersReducer (state = defaultState, action) {
	switch (action.type) {

		case LOADING : {
			return {...state, loading: true }
		}

		case USERS_GET_COUNT_SUCCESS: {
			return {...state, loading: false, count : { anonymous: action.anonymous, regular: action.regular, subscriber: action.subscriber} }
		}

		case USERS_GET_COUNT_DAILY_SUCCESS: {
			return {...state, loading: false, dailyCount : action.list }
		}

		case USERS_GET_COUNT_ERROR: {
			return {...state, loading: false, reviewsError: action.error }
		}

		case USERS_GET_LIST_SUCCESS: {
			return {...state, loading: false, userList: Array.isArray(action.appUsers) ? action.appUsers : [], anonymousUserList: Array.isArray(action.anonymousUsers) ? action.anonymousUsers : [] }
		}

		case USERS_GET_LIST_ERROR : {
			return {...state, loading: false, listError: action.error }	
		}

		case USERS_GET_BENEFITS_SUCCESS : {
			return {...state, loading: false, benefits: action.list }
		}

		case USERS_GET_BENEFITS_ERROR: {
			return {...state, loading: false, benefitsError: action.error }
		}

		case USERS_SIGN_OUT_SUBSCRIBER_SUCCESS : {
			const index = state.userList.findIndex(u => u.id*1 === action.userId*1);
			const userList = [...state.userList.slice(0,index), {...state.userList[index], status: "regular", subscriber_hash: null}, ...state.userList.slice(index+1)];
			return {...state, loading: false, userList }
		}

		case USERS_DELETE_SUCCESS: {
			const index = state.userList.findIndex(u => u.id*1 === action.userId*1);
			return {...state, loading: false, userList : [...state.userList.slice(0,index), ...state.userList.slice(index+1)] }
		}

		default:
			return state;
	}

};
