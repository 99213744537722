import {
	GET_SUPPORT_TICKETS_SUCCESS,
	SET_SUPPORT_TICKET_READ_SUCCESS,
	SET_SUPPORT_TICKET_UNREAD_SUCCESS,
	SET_SUPPORT_TICKET_SOLVED_SUCCESS,
	SET_SUPPORT_TICKET_UNSOLVED_SUCCESS,
	TOGGLE_SUPPORT_TICKETS_SHOW_SOLVED, ASSIGN_SUPPORT_TICKET_SUCCESS
} from '../actions/supportActions';

const defaultState = {
	tickets : [],
	showSolved : false
};

export default function SupportReducer (state = defaultState, action) {
	let index;
	switch (action.type) {

		case GET_SUPPORT_TICKETS_SUCCESS:
			return { ...state,
				tickets: action.list,
			};

		case SET_SUPPORT_TICKET_READ_SUCCESS:
			index = state.tickets.findIndex(ticket => ticket.id*1 === action.ticketId*1);
			return { ...state, tickets : [...state.tickets.slice(0,index), {...state.tickets[index], read: true}, ...state.tickets.slice(index+1)]};

		case SET_SUPPORT_TICKET_UNREAD_SUCCESS:
			index = state.tickets.findIndex(ticket => ticket.id*1 === action.ticketId*1);
			return { ...state, tickets : [...state.tickets.slice(0,index), {...state.tickets[index], read: false}, ...state.tickets.slice(index+1)]};

		case SET_SUPPORT_TICKET_SOLVED_SUCCESS:
			index = state.tickets.findIndex(ticket => ticket.id*1 === action.ticketId*1);
			return { ...state, tickets : [...state.tickets.slice(0,index), {...state.tickets[index], read: true, solved: true}, ...state.tickets.slice(index+1)]};

		case SET_SUPPORT_TICKET_UNSOLVED_SUCCESS:
			index = state.tickets.findIndex(ticket => ticket.id*1 === action.ticketId*1);
			return { ...state, tickets : [...state.tickets.slice(0,index), {...state.tickets[index], solved: false}, ...state.tickets.slice(index+1)]};

		case ASSIGN_SUPPORT_TICKET_SUCCESS:
			index = state.tickets.findIndex(ticket => ticket.id*1 === action.ticketId*1);
			return { ...state, tickets : [...state.tickets.slice(0,index), {...state.tickets[index], assigned_to : action.adminId}, ...state.tickets.slice(index+1)]};

		case TOGGLE_SUPPORT_TICKETS_SHOW_SOLVED:
			return {...state, showSolved : !state.showSolved };

		default:
			return state;
	}

};
